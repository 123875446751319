import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {connect} from "react-redux";
import {Formik} from 'formik';
import {getFormErrorMessage, isFormFieldInvalid, mapTranslation} from "../helpers/utils";
import * as Yup from "yup";
import {TabPanel, TabView} from "primereact/tabview";
import TranslationService from "../services/TranstationService";
import {Toolbar} from "primereact/toolbar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import './CoreTranslation.css';

class CoreTranslation extends Component {
    emptyElement = {
        code: '',
        translation: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeIndex: 0,
            elements: {},
            elementDialog: false,
            deleteElementDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            loadingDialog: false,
        };

        this.formikProps = {
            initialValues: this.emptyElement
        };

        this.elementService = new TranslationService();
        this.originalRows = {};
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
        this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.elementService.getCoreTranslations()
            .then(response => {
                    this.setState({
                        elements: response.data.content,
                        loading: false
                    })
                }
            );
    }

    codeBodyTemplate(rowData) {
        const codeClassName = classNames({
            'message': rowData.kind === 'message',
            'core': rowData.kind === 'core',
            'mixed': rowData.kind === 'mixed',
        });

        return (
            <div className={codeClassName}>
                {rowData.code}
            </div>
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            elementDialog: false,
            element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement = (element, {setStatus, setErrors}) => {
        console.log('element   create ', element)
        this.setState({
            loadingDialog: true,
        });

        this.elementService.edit(this.lang, element)
            .then((response) => {
                let elements = {...this.state.elements}
                elements[this.lang][element.code] = [element.translation, 'core']
                this.setState({
                    elements,
                    loadingDialog: false,
                    elementDialog: false
                });
                this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({loadingDialog: true});
        let payload = this.state.element
        payload['locale'] = this.lang

        this.elementService.delete(JSON.stringify(payload))
            .then(() => {
                let elements = {...this.state.elements}
                delete elements[this.lang][payload.code]

                this.setState({
                    elements,
                    deleteElementDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    leftToolbarTemplate(lang) {
        this.lang = lang
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
            </React.Fragment>
        )
    }

    onEditorValueChange(language, props, value) {
        let oldData = this.state.elements[language][props.rowData.code]
        oldData[0] = value
        let elements = {...this.state.elements}
        elements[language][props.rowData.code] = oldData;
        this.setState({elements});
    }

    inputTextEditor(language, props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(language, props, e.target.value)}/>;
    }

    translationEditor(language, props) {
        return this.inputTextEditor(language, props, 'translation');
    }

    onRowEditInit(event) {
        this.originalRows[event.index] = event.data;
    }

    onRowEditCancel(lang, event) {
        let originalLanguage = this.originalRows[event.index];
        delete this.originalRows[event.index];
        let oldData = this.state.elements[lang][event.data.code]
        oldData[0] = originalLanguage.translation
        let elements = {...this.state.elements}
        elements[lang][event.data.code] = oldData;
        this.setState({elements});
    }

    onRowEditSave(lang, event) {
        this.setState({
            loadingButton: true
        });
        let payload = {code: event.data.code, translation: event.data.translation}
        this.elementService.edit(lang, payload)
            .then((response) => {
                this.setState({
                    loadingButton: false
                });
                this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    renderFooter() {
        return (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button type="submit" form="formElementDialog" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
            </React.Fragment>
        );
    }

    actionBodyTemplate(rowData, lang) {
        this.lang = lang;
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteElement(rowData)}/>
            </React.Fragment>
        );
    }

    render() {
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <div className="card">
                    <h5>{I18n.t('CORE_TRANSLATIONS')}</h5>
                    {this.state.loading ? <>loading ...</> :
                        <TabView>
                            {Object.entries(this.state.elements).map(([lang]) => {
                                return (
                                    <TabPanel key={lang} header={I18n.t(lang)}>
                                        <div className="crud-demo">
                                            <div className="card">
                                                <Toolbar className="p-mb-4" left={() => this.leftToolbarTemplate(lang)}/>
                                                <DataTable value={mapTranslation(this.state.elements[lang])} editMode="row" dataKey="code"
                                                           onRowEditInit={this.onRowEditInit} onRowEditCancel={event => this.onRowEditCancel(lang, event)} onRowEditSave={event => this.onRowEditSave(lang, event)}
                                                           responsive
                                                >
                                                    <Column body={this.codeBodyTemplate} filter filterPlaceholder={I18n.t('SEARCH_BY_CODE')} filterMatchMode="contains" headerStyle={{width: '35%'}} field="code" header={I18n.t('CODE')}/>
                                                    <Column filter filterPlaceholder={I18n.t('SEARCH_BY_TRANSLATION')} filterMatchMode="contains" field="translation" header={I18n.t('TRANSLATION')} editor={(props) => this.translationEditor(lang, props)}/>
                                                    <Column rowEditor headerStyle={{width: '8rem'}} bodyStyle={{textAlign: 'center'}}/>
                                                    <Column headerStyle={{width: '5rem'}} body={(rowData) => this.actionBodyTemplate(rowData, lang)}/>
                                                </DataTable>
                                            </div>
                                        </div>
                                    </TabPanel>
                                )
                            })}
                        </TabView>}
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '500px'}} header={I18n.t('DETAILS')} modal footer={this.renderFooter} className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={Yup.object().shape({
                            code: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                            translation: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                        })}
                        onSubmit={this.saveElement}
                    >
                        {props => {
                            console.log(props)
                            let TRANSLATE_CODE = "TRANSLATION_IN_ENGLISH"
                            if (this.lang === "fr-FR") {
                                TRANSLATE_CODE = "TRANSLATION_IN_FRENCH"
                            }

                            return <form id="formElementDialog" onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>

                                <div className="p-grid p-formgrid p-fluid">
                                    <div className="p-col-12 p-lg-12">
                                        <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                            <InputText
                                                autoFocus
                                                name="code"
                                                value={props.values.code}
                                                onChange={props.handleChange}
                                                id="code"
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, "code")})}
                                            />
                                            {getFormErrorMessage(props, 'code')}<label htmlFor="code">{I18n.t('CODE')}</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-12">
                                        <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                            <InputText
                                                name="translation"
                                                value={props.values.translation}
                                                onChange={props.handleChange}
                                                id="translation"
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, "translation")})}
                                            />
                                            {getFormErrorMessage(props, 'translation')}<label htmlFor="translation">{I18n.t(TRANSLATE_CODE)}</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.code}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(CoreTranslation);


