import {SET_AUDIO_FILE, SET_AUDIO_VOLUME} from "./types";

export const setAudio = (payload) => ({
    type: SET_AUDIO_FILE,
    payload: payload
});

export const setVolume = (payload) => ({
    type: SET_AUDIO_VOLUME,
    payload: payload
});

