import HttpService from "./HttpService";

export default class TranslationService extends HttpService {
    endPoint = "/api/v1/core/translations"

    getDefault() {
        return this.getJsonRequest().get(this.endPoint + "/default");
    }

    getProfileAndDefault() {
        return this.getAuthTokenRequest().get(this.endPoint + "/profileAndDefault");
    }

    getCoreTranslations() {
        return this.getAuthTokenRequest().get(this.endPoint + "/core");
    }

    getProfileTranslations() {
        return this.getAuthTokenRequest().get(this.endPoint + "/profile");
    }
}

