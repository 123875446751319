import React, {Component} from 'react';
import {DashboardDefault} from "./DashboardDefault";

export const RTLContext = React.createContext();

class Dashboard extends Component {
    render() {
        return (
            <DashboardDefault/>
        );
    }
}

export default Dashboard;

