import {LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REFRESH_TOKEN_SUCCESS, REGISTER_FAIL, REGISTER_SUCCESS, SET_PROFILE} from "../actions/types";
import {Constant} from '../helpers/const';

const account = JSON.parse(localStorage.getItem(Constant.ACCOUNT));
const userProfile = JSON.parse(localStorage.getItem(Constant.USER_PROFILE));

const initialState = account && userProfile
    ? {isLoggedIn: true, account, profile: userProfile}
    : {isLoggedIn: false, account: null, profile: null};

export default function auth(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };

        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                account: payload.account,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                account: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                account: null,
            };
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                account: payload.account,
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: payload,
            };
        default:
            return state;
    }
}
