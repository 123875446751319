import React from "react";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {getFormErrorMessage2, isFormFieldInvalid2} from "../../helpers/utils";
import {I18n} from "react-redux-i18n";

const InputText2 = ({
                        field,
                        label,
                        name,
                        id,
                        value,
                        form: {touched, errors},
                        ...props
                    }) => {
    return (
        <React.Fragment>
            <InputText
                id={field.name}
                name={field.name}
                {...field}
                {...props}
                className={classNames({'p-invalid': isFormFieldInvalid2(touched, errors, field)})}
            />
            {getFormErrorMessage2(touched, errors, field)}
            <label htmlFor="name">{I18n.t(label)}</label>
        </React.Fragment>
    );
};

export default InputText2;
