import React, {Component} from 'react';
import {connect} from "react-redux";
import {logout} from './actions/auth';
import AppInlineMenuDefault from "./AppInlineMenuDefault";

class AppInlineMenu extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
    }

    logOut() {
        this.props.dispatch(logout());
    }

    render() {
        let displayName=""
        let userOccupationEnums=""
        if (this.props.account) {
            displayName = this.props.account.data.displayName
            userOccupationEnums = this.props.account.data.userOccupationEnums
        }
        return (
            <AppInlineMenuDefault
                menuKey={this.props.menuKey}
                inlineMenuActive={this.props.inlineMenuActive}
                onInlineMenuClick={this.props.onInlineMenuClick}
                horizontal={this.props.horizontal}
                menuMode={this.props.menuMode}
                logOut={this.logOut}
                account={this.props.account}
                displayName={displayName}
                userOccupationEnum={userOccupationEnums}
            />
        );
    }
}

function mapStateToProps(state) {
    const {account} = state.auth;

    return {
        account
    };
}

export default connect(mapStateToProps)(AppInlineMenu);
