import React, {Component} from 'react';
import {connect} from "react-redux";
import {setLocaleWithFallback} from "./actions/i18n";
import AppTopbarDefault from "./AppTopbarDefault";

class AppTopbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingLang: false
        };
    }

    handleLanguageLinkClick = (e, code) => {
        this.setState({
            loadingLang: true
        });
        e.preventDefault();
        this.props.dispatch(setLocaleWithFallback(code)).then(this.setState({
            loadingLang: false
        }));
    };

    render() {
        return (
            <AppTopbarDefault
                horizontal={this.props.horizontal}
                activeTopbarItem={this.props.activeTopbarItem}
                onMenuButtonClick={this.props.onMenuButtonClick}
                onTopbarItemClick={this.props.onTopbarItemClick}
                onRightMenuButtonClick={this.props.onRightMenuButtonClick}
                onMobileTopbarButtonClick={this.props.onMobileTopbarButtonClick}
                mobileTopbarActive={this.props.mobileTopbarActive}
                searchActive={this.props.searchActive}
                onSearch={this.props.onSearch}
                locale={this.props.locale}
                handleLanguageLinkClick={this.handleLanguageLinkClick}
                loadingLang={this.state.loadingLang}
            />
        );
    }
}

const mapStateToProps = state => ({locale: state.i18n.locale});
export default connect(mapStateToProps)(AppTopbar);
