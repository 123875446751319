import React from "react";
import {classNames} from "primereact/utils";
import {getFormErrorMessage2, isFormFieldInvalid2} from "../../helpers/utils";
import {FileUpload} from "primereact/fileupload";

const FileUpload2 = ({
                         field,
                         name,
                         id,
                         value,
                         form: {touched, errors},
                         ...props
                     }) => {
    return (
        <React.Fragment>
            <FileUpload
                id={field.name}
                name={field.name}
                {...field}
                {...props}
                className={classNames({'p-invalid': isFormFieldInvalid2(touched, errors, field)})}
            />
            {getFormErrorMessage2(touched, errors, field)}
        </React.Fragment>
    );
};

export default FileUpload2;
