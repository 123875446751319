import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {connect} from "react-redux";
import {Formik} from 'formik';
import {getFormErrorMessage, isFormFieldInvalid} from "../helpers/utils";
import * as Yup from "yup";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import './CoreTranslation.css';
import SettingService from "../services/SettingService";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "@material-ui/core";
import {Toolbar} from "primereact/toolbar";

class CoreSettings extends Component {
    emptyElement = {
        key: '',
        value: '',
        canOverload: true
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeIndex: 0,
            elements: {},
            elementDialog: false,
            deleteElementDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            loadingDialog: false,
        };

        this.formikProps = {
            initialValues: this.emptyElement
        };

        this.elementService = new SettingService();
        this.originalRows = {};
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
        this.keyBodyTemplate = this.keyBodyTemplate.bind(this);
        this.booleanTemplate = this.booleanTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.elementService.getRawDefault()
            .then(response => {
                    this.setState({
                        elements: response.data.content ? response.data.content : [],
                        loading: false
                    })
                }
            );
    }

    booleanTemplate(rowData) {
        let res = "NO"
        if (rowData.canOverload) {
            res = "YES"
        }

        return (
            <div>{I18n.t(res)}</div>
        );
    }

    keyBodyTemplate(rowData) {
        const keyClassName = classNames({
            'message': rowData.kind === 'message',
            'core': rowData.kind === 'core',
            'mixed': rowData.kind === 'mixed',
        });

        return (
            <div className={keyClassName}>
                {rowData.key}
            </div>
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            elementDialog: false,
            element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    findIndexByKey(key) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].key === key) {
                index = i;
                break;
            }
        }
        return index;
    }

    saveElement = (element, {setStatus, setErrors}) => {
        element['target'] = 'core'
        console.log('element   create ', element)
        this.setState({
            loadingDialog: true,
        });

        this.elementService.edit(element)
            .then(() => {
                let elements = [...this.state.elements];
                elements.unshift(element);
                this.setState({
                    elements,
                    loadingDialog: false,
                    elementDialog: false
                });
                this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.key), life: 6000});
            });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({loadingDialog: true});
        let payload = this.state.element
        payload['target'] = 'core'
        this.elementService.delete(JSON.stringify(payload))
            .then(() => {
                let elements = this.state.elements.filter(val => val.key !== this.state.element.key);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.key), life: 6000});
            });
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
            </React.Fragment>
        )
    }

    onEditorValueChange(props, value) {
        let elements = [...props.value];
        elements[props.rowIndex][props.field] = value;
        this.setState({elements});
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)}/>;
    }

    inputTextAreaEditor(props, field) {
        return <InputTextarea type="text" cols={60} style={{width: '100%'}} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)}/>;
    }

    inputCheckBoxEditor(props, field) {
        return <Checkbox checked={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.checked)}/>;
    }

    keyEditor(props) {
        return this.inputTextEditor(props, 'key');
    }

    valueEditor(props) {
        return this.inputTextAreaEditor(props, 'value');
    }

    overLoadEditor(props) {
        return this.inputCheckBoxEditor(props, 'canOverload');
    }

    onRowEditInit(event) {
        this.originalRows[event.index] = {...this.state.elements[event.index]};
    }

    onRowEditCancel(event) {
        let elements = [...this.state.elements];
        elements[event.index] = this.originalRows[event.index];
        delete this.originalRows[event.index];
        this.setState({elements});
    }

    onRowEditSave(event) {
        this.setState({
            loadingButton: true
        });

        let payload = {key: event.data.key, value: event.data.value, canOverload: event.data.canOverload, target: 'core'}
        this.elementService.edit(payload)
            .then((response) => {
                this.setState({
                    loadingButton: false
                });
                this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.key), life: 6000});
            });
    }

    renderFooter() {
        return (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button type="submit" form="formElementDialog" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
            </React.Fragment>
        );
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteElement(rowData)}/>
            </React.Fragment>
        );
    }

    render() {
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <div className="card">
                    <h5>{I18n.t('CORE_SETTINGS')}</h5>
                    <div className="crud-demo">
                        <div className="card">
                            <Toolbar className="p-mb-4" left={() => this.leftToolbarTemplate()}/>
                            <DataTable value={this.state.elements} editMode="row" dataKey="key" loading={this.state.loading}
                                       onRowEditInit={this.onRowEditInit} onRowEditCancel={event => this.onRowEditCancel(event)} onRowEditSave={event => this.onRowEditSave(event)}
                                       responsive
                            >
                                <Column body={this.keyBodyTemplate} filter filterPlaceholder={I18n.t('SEARCH_BY_KEY')} editor={(props) => this.keyEditor(props)} filterMatchMode="contains" field="key" header={I18n.t('KEY')}/>
                                <Column style={{width: '50%', overflow: 'auto'}} headerStyle={{width: '50%'}} field="value" header={I18n.t('VALUE')} editor={(props) => this.valueEditor(props)}/>
                                <Column body={this.booleanTemplate} style={{width: '20%'}} headerStyle={{width: '20%'}} field="canOverload" header={I18n.t('CAN_OVERLOAD')} editor={(props) => this.overLoadEditor(props)}/>
                                <Column style={{width: '4%'}} rowEditor headerStyle={{width: '4%'}} bodyStyle={{textAlign: 'center'}}/>
                                <Column style={{width: '4%'}} headerStyle={{width: '4%'}} body={(rowData) => this.actionBodyTemplate(rowData)}/>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '500px'}} header={I18n.t('DETAILS')} modal footer={this.renderFooter} className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={Yup.object().shape({
                            key: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                            value: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                        })}
                        onSubmit={this.saveElement}
                    >
                        {props => {
                            return <form id="formElementDialog" onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>

                                <div className="p-grid p-formgrid p-fluid">
                                    <div className="p-col-12 p-lg-12">
                                        <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                            <InputText
                                                autoFocus
                                                name="key"
                                                value={props.values.key}
                                                onChange={props.handleChange}
                                                id="key"
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, "key")})}
                                            />
                                            {getFormErrorMessage(props, 'key')}<label htmlFor="key">{I18n.t('KEY')}</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-12">
                                        <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                            <InputText
                                                name="value"
                                                value={props.values.value}
                                                onChange={props.handleChange}
                                                id="value"
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, "value")})}
                                            />
                                            {getFormErrorMessage(props, 'value')}<label htmlFor="value">{I18n.t('VALUE')}</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-12">
                                        <div className="p-field-checkbox" style={{marginTop: '10px'}}>
                                            <Checkbox
                                                id="canOverload"
                                                value={props.values.canOverload}
                                                name="canOverload"
                                                onChange={props.handleChange}
                                                checked={props.values.canOverload}>
                                            </Checkbox>
                                            <label htmlFor="required">{I18n.t('CAN_OVERLOAD')}</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.key}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(CoreSettings);


