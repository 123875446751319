import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import * as Yup from 'yup';
import {Formik} from 'formik';
import TemplateGroupService from "../services/TemplateGroupService";
import {getFormErrorMessage, isFormFieldInvalid} from "../helpers/utils";
import {InputTextarea} from "primereact/inputtextarea";
import {InputNumber} from "primereact/inputnumber";
import RuleService from "../services/RuleService";

class Rule extends Component {
    emptyElement = {
        name: '',
        description: '',
        triggerName: '',
        triggerGroup: '',
        ruleStatusEnum: '',
        priority: 0,
        conditions: [],
        actions: [],
    };

    emptyCondition = {
        parameter: '',
        operator: '',
        criteria: ''
    };

    emptyAction = {
        ruleActionTypeEnum: '',
        templateGroupId: '',
        templateGroupName: '',
        name: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            originalTemplate: true,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                name: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                triggerName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                triggerGroup: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                description: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                ruleStatusEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                priority: Yup.number().positive(I18n.t('POSITIVE_NUMBER_REQUIRED')).integer().required(I18n.t('FIELD_IS_REQUIRED')),
                conditions: Yup.array().min(1, I18n.t('FIELD_IS_REQUIRED')),
                actions: Yup.array().min(1, I18n.t('FIELD_IS_REQUIRED')),
            })
        };

        this.formikConditionProps = {
            initialValues: this.emptyCondition,
            validationSchema: Yup.object().shape({
                parameter: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                operator: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
            })
        };

        this.formikActionProps = {
            initialValues: this.emptyAction,
            validationSchema: Yup.object().shape({
                templateGroupId: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                templateGroupName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                ruleActionTypeEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                name: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
            })
        };

        this.ruleStatus = [
            {label: I18n.t('ACTIVE'), value: 'ACTIVE'},
            {label: I18n.t('INACTIVE'), value: 'INACTIVE'},
        ];

        this.elementService = new RuleService();
        this.ruleService = new RuleService();
        this.templateService = new TemplateGroupService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.onFileSelect = this.onFileSelect.bind(this);
        this.onFileClear = this.onFileClear.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);
        this.leftDescriptionToolbarConditionsTemplate = this.leftDescriptionToolbarConditionsTemplate.bind(this);
        this.leftDescriptionToolbarActionsTemplate = this.leftDescriptionToolbarActionsTemplate.bind(this);
        this.openNewCondition = this.openNewCondition.bind(this);
        this.openNewAction = this.openNewAction.bind(this);
        this.hideConditionDialog = this.hideConditionDialog.bind(this);
        this.hideActionDialog = this.hideActionDialog.bind(this);
        this.hideDeleteConditionDialog = this.hideDeleteConditionDialog.bind(this);
        this.hideDeleteActionDialog = this.hideDeleteActionDialog.bind(this);
        this.actionConditionBodyTemplate = this.actionConditionBodyTemplate.bind(this);
        this.actionActionBodyTemplate = this.actionActionBodyTemplate.bind(this);
        this.deleteElementCondition = this.deleteElementCondition.bind(this);
        this.onActionRowReorder = this.onActionRowReorder.bind(this);
        this.deleteElementAction = this.deleteElementAction.bind(this);
    }

    componentDidMount() {
        this.createOptions();
        this.loadParameters();
        this.loadOperators();
        this.loadTriggers();
        this.loadTemplates()
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }
    }

    loadOperators() {
        this.ruleService.getFactOperators().then(
            response => {
                let operators = []
                if (response.data.content) {
                    for (let i = 0; i < response.data.content.length; i++) {
                        let operator = response.data.content[i];
                        operators.push({label: I18n.t(operator.factOperator), value: operator.factOperator, type: operator.ruleOperatorType})
                    }
                }
                this.setState({operators})
            }
        );
    }

    loadTemplates() {
        this.templateService.getAll().then(
            response => {
                console.log('tepll  ', response.data.content)
                let templates = []
                if (response.data.content) {
                    for (let i = 0; i < response.data.content.length; i++) {
                        let template = response.data.content[i];
                        templates.push({label: template.name + ' - ' + template.ruleActionTypeEnum, id: template.id, name: template.name, ruleActionTypeEnum: template.ruleActionTypeEnum})
                    }
                }
                this.setState({templates})
            }
        );
    }

    loadTriggers() {
        this.ruleService.getFactTriggers().then(
            response => {
                let triggers = []
                for (let key in response.data.content) {
                    let items = []
                    let rawItems = response.data.content[key];
                    for (let i = 0; i < rawItems.length; i++) {
                        let item = {label: I18n.t(rawItems[i]), name: rawItems[i], group: key}
                        items.push(item)
                    }
                    let trigger = {
                        label: I18n.t(key),
                        items: items
                    }
                    triggers.push(trigger);
                }
                this.setState({triggers})
            }
        );
    }

    loadParameters() {
        this.ruleService.getFactParameters().then(
            response => {
                let parameters = []
                for (let key in response.data.content) {
                    let items = []
                    let rawItems = response.data.content[key];
                    console.log('raw items  ', rawItems)
                    for (let key in rawItems) {
                        let item = {label: I18n.t(key), value: key, type: rawItems[key]}
                        items.push(item)
                    }
                    let parameter = {
                        label: I18n.t(key),
                        items: items
                    }
                    parameters.push(parameter);
                }
                this.setState({parameters})
            }
        );
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('EDIT_DATA'), icon: 'pi pi-fw pi-pencil', command: () => this.editElement(this.state.selectedElement)},
            {label: I18n.t('DELETE_DATA'), icon: 'pi pi-fw pi-trash', command: () => this.confirmDeleteElement(this.state.selectedElement)}
        ];

        const sortFilterValues = [
            {name: I18n.t('NAME') + ' ' + I18n.t('ASCENDING'), field: 'name', order: Order.ASC},
            {name: I18n.t('NAME') + ' ' + I18n.t('DESCENDING'), field: 'name', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue = [{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            elementDialog: true
        });
    }

    hideDialog() {
        this.formikProps.initialValues = this.state.element
        this.setState({
            elementDialog: false,
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
        this.setState({deleteElementConditionDialog: false});
        this.setState({deleteElementActionDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveCondition = (element, {setStatus, setErrors}) => {
        let conditions = this.formikProps.initialValues.conditions
        conditions.push(element)
        this.formikProps.initialValues["conditions"] = conditions
        this.formikConditionProps.initialValues = this.emptyCondition;
        this.setState({
            conditionDialog: false
        });
        this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
    }

    saveAction = (element, {setStatus, setErrors}) => {
        let actions = this.formikProps.initialValues.actions
        actions.push(element)
        this.formikProps.initialValues["actions"] = actions
        this.formikActionProps.initialValues = this.emptyAction;
        this.setState({
            actionDialog: false
        });
        this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
    }

    saveElement = (element, {setStatus, setErrors}) => {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        for (let i = 0; i < element.actions.length; i++) {
            element.actions[i].order = i + 1
        }

        if (element.id) { //may be update
            this.elementService.edit(element.id, element)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        } else { //may be creation
            this.elementService.add(element)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        }
    }

    editElement(element) {
        this.formikProps.initialValues = {...element};
        this.setState({
            elementDialog: true,
            element
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        let searchValue = [{field: 'name', value: searchFilterValue}];
        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue?.field, sortFilterValue?.order, searchValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000});
            });
    }

    onFileSelect(event) {
        let element = {...this.state.element};
        element["selectedFile"] = event.files[0];
        this.setState({element});
    }

    onFileClear() {
        let element = {...this.state.element};
        element["selectedFile"] = null;
        this.setState({element});
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
                <Button type="button" label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label={I18n.t('IMPORT_DATA')} chooseLabel="Import" className="p-mr-2 p-d-inline-block"/>
                <Button type="button" label={I18n.t('EXPORT_DATA')} icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV}/>
            </React.Fragment>
        )
    }

    imageBodyTemplate(rowData) {
        return <img src={rowData.image.url} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image.name} className="product-image"/>
    }

    leftDescriptionToolbarConditionsTemplate(props) {
        this.formikProps.initialValues = props.values
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_CONDITION')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNewCondition}/>
            </React.Fragment>
        )
    }

    leftDescriptionToolbarActionsTemplate(props) {
        this.formikProps.initialValues = props.values
        return (
            <React.Fragment>
                <Button type="button" label={I18n.t('ADD_ACTION')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNewAction}/>
            </React.Fragment>
        )
    }

    openNewCondition() {
        this.setState({
            conditionDialog: true
        });
    }

    openNewAction() {
        this.setState({
            actionDialog: true
        });
    }

    hideConditionDialog() {
        this.setState({
            conditionDialog: false
        });
    }

    hideActionDialog() {
        this.setState({
            actionDialog: false
        });
    }

    hideDeleteConditionDialog() {
        this.setState({deleteConditionDialog: false});
    }

    hideDeleteActionDialog() {
        this.setState({deleteActionDialog: false});
    }

    confirmDeleteConditionSelected(elementCondition) {
        this.setState({elementCondition, deleteElementConditionDialog: true});
    }

    confirmDeleteActionSelected(elementAction) {
        this.setState({elementAction, deleteElementActionDialog: true});
    }

    actionConditionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteConditionSelected(rowData)}/>
            </React.Fragment>
        );
    }

    actionActionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteActionSelected(rowData)}/>
            </React.Fragment>
        );
    }

    deleteElementCondition() {
        this.formikProps.initialValues["conditions"] = this.formikProps.initialValues.conditions.filter(val => (val.parameter !== this.state.elementCondition.parameter || val.operator !== this.state.elementCondition.operator || val.criteria !== this.state.elementCondition.criteria))
        this.setState({
            deleteElementConditionDialog: false
        });
        this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
    }

    deleteElementAction() {
        this.formikProps.initialValues["actions"] = this.formikProps.initialValues.actions.filter(val => val.templateGroupId !== this.state.elementAction.templateGroupId)
        this.setState({
            deleteElementActionDialog: false
        });
        this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
    }

    onActionRowReorder(e, props) {
        props.setFieldValue('actions', e.value)
    }

    handleTemplate(value, props) {
        props.setFieldValue('template', value)
        props.setFieldValue('templateGroupName', value.name)
        props.setFieldValue('templateGroupId', value.id)
        props.setFieldValue('ruleActionTypeEnum', value.ruleActionTypeEnum)
    }

    buildTriggerValue(props) {
        return {
            "label": I18n.t(props.values.triggerName),
            "name": props.values.triggerName,
            "group": props.values.triggerGroup
        }
    }


    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('TEMPLATES')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue} onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue} onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
            </div>
        );
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );
        const deleteElementsDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedElements}/>
            </React.Fragment>
        );

        const deleteElementConditionDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElementCondition}/>
            </React.Fragment>
        );

        const deleteElementActionDialogFooter = (
            <React.Fragment>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElementAction}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el} onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}/>

                    <DataTable ref={(el) => this.dt = el} value={this.state.elements} selection={this.state.selectedElements} onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement} onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>

                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
                        <Column field="name" header={I18n.t('NAME')}/>
                        <Column field="triggerName" header={I18n.t('TRIGGER')}/>
                        <Column field="ruleStatusEnum" header={I18n.t('RULE_STATUS')}/>
                        <Column field="priority" header={I18n.t('PRIORITY')}/>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '950px'}} header={I18n.t('DETAILS')} modal className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikProps.initialValues}
                        validationSchema={this.formikProps.validationSchema}
                        onSubmit={this.saveElement}
                    >
                        {props => {
                            console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>

                                <div className="p-grid p-formgrid p-fluid">
                                    <div className="p-float-label p-field p-col-12 p-lg-3" style={{marginTop: '10px'}}>
                                        <InputText
                                            autoFocus
                                            name="name"
                                            value={props.values.name}
                                            onChange={props.handleChange}
                                            id="name"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, "name")})}
                                        />
                                        {getFormErrorMessage(props, 'name')}
                                        <label htmlFor="name">{I18n.t('RULE_NAME')}</label>
                                    </div>

                                    <div className="p-float-label p-field p-col-12 p-lg-3" style={{marginTop: '10px'}}>
                                        <Dropdown
                                            id='trigger'
                                            name="trigger"
                                            value={this.buildTriggerValue(props)}
                                            options={this.state.triggers}
                                            filter
                                            onChange={(event) => props.setFieldValue('triggerGroup', event.value.group) && props.setFieldValue('triggerName', event.value.name)}
                                            optionLabel="label"
                                            filterBy="label"
                                            optionGroupLabel="label"
                                            optionGroupChildren="items"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'triggerName')})}
                                        />
                                        {getFormErrorMessage(props, 'trigger')}
                                        <label htmlFor="trigger" className={classNames({'p-error': isFormFieldInvalid(props, 'trigger')})}>{I18n.t('TRIGGER')}*</label>
                                    </div>

                                    <div className="p-float-label p-field p-col-12 p-lg-3" style={{marginTop: '10px'}}>
                                        <InputNumber
                                            name="priority"
                                            value={props.values.priority}
                                            onValueChange={props.handleChange}
                                            id="priority"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, "priority")})}
                                        />
                                        {getFormErrorMessage(props, 'priority')}
                                        <label htmlFor="priority">{I18n.t('PRIORITY')}</label>
                                    </div>

                                    <div className="p-float-label p-field p-col-12 p-lg-3" style={{marginTop: '10px'}}>
                                        <Dropdown
                                            id='ruleStatusEnum'
                                            name="ruleStatusEnum"
                                            value={props.values.ruleStatusEnum}
                                            options={this.ruleStatus}
                                            onChange={(event) => props.setFieldValue('ruleStatusEnum', event.value)}
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'ruleStatusEnum')})}
                                        />
                                        {getFormErrorMessage(props, 'ruleStatusEnum')}
                                        <label htmlFor="ruleStatusEnum" className={classNames({'p-error': isFormFieldInvalid(props, 'ruleStatusEnum')})}>{I18n.t('RULE_STATUS')}*</label>
                                    </div>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        name="description"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "description")})}
                                    />
                                    {getFormErrorMessage(props, 'description')}
                                    <label htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                </div>

                                <div className="card">
                                    {getFormErrorMessage(props, 'conditions')}
                                    <Toolbar className="p-mb-4" left={this.leftDescriptionToolbarConditionsTemplate(props)}/>
                                    <DataTable ref={(el) => this.dt = el} value={props.values.conditions}>
                                        <Column field="parameter" header={I18n.t('PARAMETER')}/>
                                        <Column field="operator" header={I18n.t('OPERATOR')}/>
                                        <Column field="criteria" header={I18n.t('CRITERIA')}/>
                                        <Column style={{width: '3em'}} body={this.actionConditionBodyTemplate}/>
                                    </DataTable>
                                </div>

                                <div className="card">
                                    {getFormErrorMessage(props, 'actions')}
                                    <Toolbar className="p-mb-4" left={this.leftDescriptionToolbarActionsTemplate(props)}/>
                                    <DataTable reorderableColumns ref={(el) => this.dt = el} value={props.values.actions}
                                               dataKey="templateGroupId"
                                               onRowReorder={(e) => this.onActionRowReorder(e, props)}>
                                        <Column rowReorder style={{width: '3em'}}/>
                                        <Column field="order" header={I18n.t('ORDER')}/>
                                        <Column field="ruleActionTypeEnum" header={I18n.t('TYPE')}/>
                                        <Column field="name" header={I18n.t('NAME')}/>
                                        <Column field="templateGroupName" header={I18n.t('TEMPLATE_GROUP')}/>
                                        <Column style={{width: '3em'}} body={this.actionActionBodyTemplate}/>
                                    </DataTable>
                                </div>

                                <div className="p-dialog-footer">
                                    <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                                    <Button type="submit" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
                                </div>
                            </form>
                        }}
                    </Formik>

                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.nameFr}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false} onHide={this.hideDeleteElementsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING" count={this.state.selectedElements.length}/>}
                    </div>
                </Dialog>


                <Dialog visible={this.state.conditionDialog} style={{width: '450px'}} header={I18n.t('DETAILS')} modal className="p-fluid" closable={false} onHide={this.hideConditionDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikConditionProps.initialValues}
                        validationSchema={this.formikConditionProps.validationSchema}
                        onSubmit={this.saveCondition}
                    >
                        {props => {
                            console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        id='parameter'
                                        name="parameter"
                                        value={props.values.parameter}
                                        options={this.state.parameters}
                                        filter
                                        onChange={(event) => props.setFieldValue('parameter', event.value)}
                                        optionLabel="label"
                                        filterBy="label"
                                        optionGroupLabel="label"
                                        optionGroupChildren="items"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'parameter')})}
                                    />
                                    {getFormErrorMessage(props, 'parameter')}<label htmlFor="parameter">{I18n.t('PARAMETER')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        id='operator'
                                        name="operator"
                                        value={props.values.operator}
                                        options={this.state.operators}
                                        filter
                                        onChange={(event) => props.setFieldValue('operator', event.value)}
                                        optionLabel="label"
                                        filterBy="label"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'operator')})}
                                    />
                                    {getFormErrorMessage(props, 'operator')}<label htmlFor="operator">{I18n.t('OPERATOR')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="criteria"
                                        value={props.values.criteria}
                                        onChange={props.handleChange}
                                        id="criteria"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "criteria")})}
                                    />
                                    {getFormErrorMessage(props, 'criteria')}<label htmlFor="criteria">{I18n.t('CRITERIA')}</label>
                                </div>
                                <div className="p-dialog-footer">
                                    <React.Fragment>
                                        <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideConditionDialog}/>
                                        <Button type="submit" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
                                    </React.Fragment>
                                </div>
                            </form>
                        }}
                    </Formik>

                </Dialog>

                <Dialog visible={this.state.actionDialog} style={{width: '450px'}} header={I18n.t('DETAILS')} modal className="p-fluid" closable={false} onHide={this.hideActionDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.formikActionProps.initialValues}
                        validationSchema={this.formikActionProps.validationSchema}
                        onSubmit={this.saveAction}
                    >
                        {props => {
                            console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        id="name"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "name")})}
                                    />
                                    {getFormErrorMessage(props, 'name')}<label htmlFor="name">{I18n.t('NAME')}</label>
                                </div>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        id='templateGroupId'
                                        name="templateGroupId"
                                        value={props.values.template}
                                        options={this.state.templates}
                                        filter
                                        onChange={(event) => this.handleTemplate(event.value, props)}
                                        optionLabel="label"
                                        filterBy="label"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'templateGroupId')})}
                                    />
                                    {getFormErrorMessage(props, 'templateGroupId')}<label htmlFor="templateGroupId">{I18n.t('TEMPLATE_GROUP')}</label>
                                </div>

                                <div className="p-dialog-footer">
                                    <React.Fragment>
                                        <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideActionDialog}/>
                                        <Button type="submit" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
                                    </React.Fragment>
                                </div>
                            </form>
                        }}
                    </Formik>

                </Dialog>


                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.name}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementConditionDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementConditionDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.elementCondition && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={I18n.t(this.state.elementCondition.parameter)}/>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteElementActionDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementActionDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.elementAction && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={I18n.t(this.state.elementAction.name)}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(Rule);


