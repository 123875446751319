import {SET_SETTING} from "../actions/types";

const initialState = {};

export default function setting(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SETTING:
      return payload;

    default:
      return state;
  }
}
