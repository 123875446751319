import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {Formik} from 'formik';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import {
    getFormErrorMessage, isFormFieldInvalid, localeFrFROptions, price
} from "../helpers/utils";
import * as Yup from 'yup';
import SongService from "../services/SongService";
import AdvertService from "../services/AdvertService";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {addLocale} from "primereact/api";
import AlbumService from "../services/AlbumService";
import ImageCrop from "./ImageCrop";

class Advert extends Component {
    emptyElement = {
        title: '',
        type: '',
        photo: null,
        tempPhoto: null,
        targetIdentifier: '',
        price: price,
        startDate: null,
        endDate: null
    };

    constructor(props) {
        super(props);

        this.formikProps = {
            initialValues: this.emptyElement, validationSchema: Yup.object().shape({
                title: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                photo: Yup.mixed()
                    .test("not-photo-required-alert", I18n.t('FIELD_IS_REQUIRED'), function (photo) {
                        const {id} = this.parent;
                        return (!id && photo !== null) || (!!id);
                    }),
                type: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                targetIdentifier: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
            }), onSubmit: (element) => {
                this.saveElement(element)
            }
        };

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null,
            src: null,
            crops: {},
            imageSrc: null,
            crop: {x: 0, y: 0},
            zoom: 1,
            aspect: 400 / 200
        };

        addLocale('fr', localeFrFROptions);

        this.elementService = new AdvertService();
        this.albumService = new AlbumService();
        this.songsService = new SongService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.searchCountry = this.searchCountry.bind(this);
        this.cancelPhotoCrop = this.cancelPhotoCrop.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
    }

    componentDidMount() {
        this.createOptions();
        this.loadSongs();
        this.loadAlbums();
        this.loadAdvertTypes();
        this.loadDevices();
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue).then(response => {
            this.setState({
                elements: response.data.content.list ? response.data.content.list : [],
                totalRecords: response.data.content.totalCount,
                loading: false
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
            this.loadAdvertTypes();
            this.loadDevices();

        }
    }

    loadDevices() {
        this.priceEnums = [{name: I18n.t('XAF'), code: 'XAF'}, {name: I18n.t('XOF'), code: 'XOF'}, {
            name: I18n.t('USD'),
            code: 'USD'
        }, {name: I18n.t('EUR'), code: 'EUR'}];
    }

    loadAdvertTypes() {
        this.advertTypes = [
            {name: I18n.t('SONG'), code: 'SONG'},
            {name: I18n.t('ALBUM'), code: 'ALBUM'},
            {name: I18n.t('PLAYLIST'), code: 'PLAYLIST'},
            {name: I18n.t('EVENT'), code: 'EVENT'}
        ];
    }

    searchCountry(event) {
        let filteredCountries;
        if (!event.query.trim().length) {
            filteredCountries = [...this.state.countriesList];
        } else {
            filteredCountries = this.state.countriesList.filter((country) => {
                return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        this.setState({filteredCountries});
    }

    loadSongs() {
        this.songsService.getAll().then(response => {
            let songs = []
            if (response.data.content) {
                for (let i = 0; i < response.data.content.length; i++) {
                    let song = response.data.content[i];
                    songs.push({label: song.formattedTitle, id: song.identifier})
                }
            }
            this.setState({songs})
        });
    }

    loadAlbums() {
        this.albumService.getAll().then(response => {
            let albums = []
            if (response.data.content) {
                for (let i = 0; i < response.data.content.length; i++) {
                    let album = response.data.content[i];
                    albums.push({label: album.name, id: album.identifier})
                }
            }
            this.setState({albums})
        });
    }

    createOptions() {
        const menuModel = [{
            label: I18n.t('EDIT_DATA'),
            icon: 'pi pi-fw pi-pencil',
            command: () => this.editElement(this.state.selectedElement)
        }, {
            label: I18n.t('DELETE_DATA'),
            icon: 'pi pi-fw pi-trash',
            command: () => this.confirmDeleteElement(this.state.selectedElement)
        }];

        const sortFilterValues = [{
            name: I18n.t('TITLE') + ' ' + I18n.t('ASCENDING'),
            field: 'pseudo',
            order: Order.ASC
        }, {name: I18n.t('TITLE') + ' ' + I18n.t('DESCENDING'), field: 'pseudo', order: Order.DESC}];
        this.setState(({
            menuModel, sortFilterValues
        }));
    }

    monthNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options}
                         onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{lineHeight: 1}}/>;
    }

    yearNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options}
                         onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2"
                         style={{lineHeight: 1}}/>;
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        this.elementService.getPage(page, rows, this.state.sortFilterValue, this.state.searchFilterValue).then(response => {
            this.setState({
                first,
                elements: response.data.content.list ? response.data.content.list : [],
                totalRecords: response.data.content.totalCount,
                loading: false
            })
        });
    }

    openNew() {
        this.setState({
            elementDialog: true
        });
    }

    hideDialog() {
        this.formikProps.initialValues = this.emptyElement;
        this.setState({
            elementDialog: false, element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement(element) {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        const data = new FormData();
        if (element.photo) {
            data.append("photo", element.photo, element.photo.name);
            element.crops = this.state.crops;
        }
        data.append("data", JSON.stringify(element));
        if (element.id) { //may be update
            this.elementService.edit(element.id, data)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    elements[index] = response.data.content;
                    this.setState({
                        elements, elementDialog: false, loadingDialog: false, element: this.emptyElement,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        } else { //may be creation
            this.elementService.add(data)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000
                    });
                });
        }
    }

    editElement(element) {
        element.tempPhoto = element.photo
        element.photo = null
        this.formikProps.initialValues = element;
        this.setState({
            elementDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element, deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({
                    severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false, element: this.emptyElement, loadingDialog: false
                });
                this.toast.show({
                    severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000
                });
            });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({
                    severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false, loadingDialog: false
                });
                this.toast.show({
                    severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000
                });
            });
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue, searchFilterValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({
                    severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000
                });
            });
    }

    leftToolbarTemplate() {
        return (<React.Fragment>
            <Button label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2"
                    onClick={this.openNew}/>
            <Button label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger"
                    onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
        </React.Fragment>)
    }

    rightToolbarTemplate() {
        return (<React.Fragment>
            <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label={I18n.t('IMPORT_DATA')}
                        chooseLabel="Import" className="p-mr-2 p-d-inline-block"/>
            <Button label={I18n.t('EXPORT_DATA')} icon="pi pi-upload" className="p-button-help"
                    onClick={this.exportCSV}/>
        </React.Fragment>)
    }

    onCropComplete = (croppedAreaPixels) => {
        let crops = {...this.state.crops};
        crops[this.file.name] = croppedAreaPixels.x + ',' + croppedAreaPixels.y + ',' + croppedAreaPixels.width + ',' + croppedAreaPixels.height
        this.setState({crops});
    };

    cancelPhotoCrop() {
        this.setState({loadingProcessingDialog: true});
        let filesRow = document.getElementsByClassName("p-fileupload-row");
        let targetRow;
        for (let i = 0; i < filesRow.length; i++) {
            let element = filesRow[i];
            for (let i = 0; i < element.childNodes.length; i++) {
                let child = element.childNodes[i];
                if (child.textContent === this.file.name) {
                    targetRow = element;
                }
            }
        }
        let button = targetRow.getElementsByTagName('button')[0]
        button.click()
        this.setState({elementPhotoDialog: false, loadingProcessingDialog: false});
    }

    startCrop = (file) => {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({src: reader.result})
        );
        reader.readAsDataURL(file);
        this.file = file
        this.setState({elementPhotoDialog: true, imageSrc: file.objectURL})
    };

    cancelCrop = (file) => {
        let crops = {...this.state.crops};
        Object.keys(crops).forEach(function (key) {
            if (key === file.name) {
                delete crops[key];
            }
        });
        this.setState({crops});
    };

    render() {
        const header = (<div className="table-header">
            <h5 className="p-m-0">{I18n.t('ADVERTS')}</h5>
            <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue}
                               onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')}
                               placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue}
                           onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name"
                           placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
        </div>);
        const deleteElementDialogFooter = (<React.Fragment>
            <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times"
                    className="p-button-text" onClick={this.hideDeleteElementDialog}/>
            <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check"
                    className="p-button-text" onClick={this.deleteElement}/>
        </React.Fragment>);
        const deleteElementsDialogFooter = (<React.Fragment>
            <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times"
                    className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
            <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check"
                    className="p-button-text" onClick={this.deleteSelectedElements}/>
        </React.Fragment>);

        const actionBodyTemplate = (rowData) => {
            return (<React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2"
                        onClick={() => this.editElement(rowData)}/>
            </React.Fragment>);
        }

        return (<div className="crud-demo">
            <Toast ref={(el) => this.toast = el}/>
            <ContextMenu model={this.state.menuModel} ref={el => this.cm = el}
                         onHide={() => this.setState({selectedElement: null})}/>
            <div className="card">
                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}/>
                <DataTable ref={(el) => this.dt = el} value={this.state.elements}
                           selection={this.state.selectedElements}
                           onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                           dataKey="id" paginator lazy loading={this.state.loading} responsive
                           contextMenuSelection={this.state.selectedElement}
                           onContextMenu={e => this.cm.show(e.originalEvent)}
                           onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                           rows={this.state.pageSize} totalRecords={this.state.totalRecords}
                           first={this.state.first} onPage={this.onPage}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                           header={header}>
                    <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
                    <Column field="title" header={I18n.t('TITLE')}/>
                    <Column field="type" header={I18n.t('TYPE')}/>
                    <Column field="views" header={I18n.t('VIEWS')}/>
                    <Column headerStyle={{width: '3rem'}} body={actionBodyTemplate}/>
                </DataTable>
            </div>

            <Dialog visible={this.state.elementDialog} style={{width: '550px'}} header={I18n.t('DETAILS')} modal
                    className="p-fluid" closable={false} onHide={this.hideDialog}>
                <Formik {...this.formikProps} >
                    {props => {
                        console.log(props.values)
                        return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                     onSubmit={props.handleSubmit} className="p-fluid">
                            <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                <InputText
                                    autoFocus
                                    name="title"
                                    value={props.values.title}
                                    onChange={props.handleChange}
                                    id="title"
                                    className={classNames({'p-invalid': isFormFieldInvalid(props, 'title')})}
                                />
                                {getFormErrorMessage(props, 'title')}
                                <label htmlFor="title"
                                       className={classNames({'p-error': isFormFieldInvalid(props, 'title')})}>{I18n.t('TITLE')}*</label>
                            </div>

                            <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                <Dropdown
                                    name="type"
                                    id="type"
                                    value={props.values.type}
                                    options={this.advertTypes}
                                    onChange={(event) => props.setFieldValue('type', event.value) && props.setFieldValue('targetIdentifier', '')}
                                    optionLabel="name"
                                    optionValue="code"
                                    className={classNames({'p-invalid': isFormFieldInvalid(props, 'type')})}
                                />
                                {getFormErrorMessage(props, 'type')}
                                <label htmlFor="type"
                                       className={classNames({'p-error': isFormFieldInvalid(props, 'type')})}>{I18n.t('ADVERT_TYPE')}*</label>
                            </div>

                            {props.values.type === 'ALBUM' &&
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        filter filterBy="label"
                                        name="targetIdentifier"
                                        id="targetIdentifier"
                                        value={props.values.targetIdentifier}
                                        options={this.state.albums}
                                        onChange={props.handleChange}
                                        optionLabel="label"
                                        optionValue="id"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'targetIdentifier')})}
                                    />
                                    {getFormErrorMessage(props, 'targetIdentifier')}
                                    <label htmlFor="targetIdentifier"
                                           className={classNames({'p-error': isFormFieldInvalid(props, 'targetIdentifier')})}>{I18n.t('ALBUM_NAME')}</label>
                                </div>}

                            {props.values.type === 'SONG' &&
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <Dropdown
                                        filter filterBy="label"
                                        name="targetIdentifier"
                                        id="targetIdentifier"
                                        value={props.values.targetIdentifier}
                                        options={this.state.songs}
                                        onChange={props.handleChange}
                                        optionLabel="label"
                                        optionValue="id"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'targetIdentifier')})}
                                    />
                                    {getFormErrorMessage(props, 'targetIdentifier')}
                                    <label htmlFor="targetIdentifier"
                                           className={classNames({'p-error': isFormFieldInvalid(props, 'targetIdentifier')})}>{I18n.t('SONG_NAME')}</label>
                                </div>}

                            <div className="p-field" style={{marginTop: '15px'}}>
                                <label htmlFor="startDate"
                                       className={classNames({'p-error': isFormFieldInvalid(props, 'startDate')})}>{I18n.t('START_DATE')}</label>
                                <Calendar
                                    id="startDate"
                                    value={props.values.startDate && new Date(props.values.startDate)}
                                    onChange={(event) => props.setFieldValue('startDate', event.value)}
                                    monthNavigator yearNavigator yearRange="1900:2030"
                                    monthNavigatorTemplate={this.monthNavigatorTemplate}
                                    yearNavigatorTemplate={this.yearNavigatorTemplate}
                                    dateFormat="dd/mm/yy"
                                    locale="fr"
                                    showTime
                                    showIcon/>
                            </div>

                            <div className="p-field" style={{marginTop: '15px'}}>
                                <label htmlFor="endDate"
                                       className={classNames({'p-error': isFormFieldInvalid(props, 'endDate')})}>{I18n.t('END_DATE')}</label>
                                <Calendar
                                    id="endDate"
                                    value={props.values.endDate && new Date(props.values.endDate)}
                                    onChange={(event) => props.setFieldValue('endDate', event.value)}
                                    monthNavigator yearNavigator yearRange="1900:2030"
                                    monthNavigatorTemplate={this.monthNavigatorTemplate}
                                    yearNavigatorTemplate={this.yearNavigatorTemplate}
                                    dateFormat="dd/mm/yy"
                                    locale="fr"
                                    showTime
                                    showIcon/>
                            </div>


                            <div className="p-grid p-fluid" style={{marginTop: '10px'}}>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <Button style={{width: '100%'}}
                                                className={classNames({'p-error': isFormFieldInvalid(props, 'price', 'etPrice')})}
                                                label={I18n.t('PRICE_WITHOUT_TAX')}/>
                                        <InputNumber
                                            name="price"
                                            mode="decimal"
                                            minFractionDigits={2}
                                            value={props.values.price.etPrice}
                                            onValueChange={(event) => props.setFieldValue('price.etPrice', event.value)}
                                            id="price"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'price', 'etPrice')})}
                                        />
                                        {getFormErrorMessage(props, 'price', 'etPrice')}
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <Button
                                            className={classNames({'p-error': isFormFieldInvalid(props, 'price', 'priceCurrencyEnum')})}
                                            label={I18n.t('CURRENCY')}/>
                                        <Dropdown
                                            name="price-currency"
                                            id="price-currency"
                                            value={props.values.price.priceCurrencyEnum}
                                            options={this.priceEnums}
                                            onChange={(event) => props.setFieldValue('price.priceCurrencyEnum', event.value)}
                                            optionLabel="name"
                                            optionValue="code"
                                            className={classNames({'p-invalid': isFormFieldInvalid(props, 'price', 'priceCurrencyEnum')})}
                                        />
                                        {getFormErrorMessage(props, 'price', 'priceCurrencyEnum')}
                                    </div>
                                </div>
                            </div>

                            {!!props.values.tempPhoto &&
                                <img src={props.values.tempPhoto.url} alt={props.values.tempPhoto.name}
                                     style={{width: '100%', display: 'block'}}/>}
                            <div className="p-field">
                                <FileUpload
                                    chooseLabel={props.values.id ? I18n.t('CHANGE_PHOTO') : I18n.t('CHOOSE_PHOTO')}
                                    uploadLabel={I18n.t('UPLOAD')} cancelLabel={I18n.t('CANCEL')} name="photo"
                                    customUpload
                                    disabled={!!props.values.photo}
                                    onSelect={(event) => {
                                        props.setFieldValue('photo', event.files[0])
                                        this.startCrop(event.files[0])
                                    }}
                                    onClear={() => props.setFieldValue('photo', null)}
                                    onRemove={(event) => {
                                        props.setFieldValue('photo', null)
                                        this.cancelCrop(event.file)
                                    }}
                                    accept="image/*" maxFileSize={100000000}
                                    className={classNames({'p-invalid': isFormFieldInvalid(props, 'photo')})}
                                />
                                {getFormErrorMessage(props, 'photo')}
                            </div>


                            <div className="p-dialog-footer">
                                <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')}
                                        icon="pi pi-times" className="p-button-text p-button-help"
                                        onClick={this.hideDialog}/>
                                <Button type="submit" loading={this.state.loadingDialog} label={I18n.t('SAVE')}
                                        icon="pi pi-check" className="p-button-text"/>
                            </div>
                        </form>
                    }}
                </Formik>

            </Dialog>

            <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}}
                    header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false}
                    onHide={this.hideDeleteElementDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                    {this.state.element &&
                        <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.name}/>}
                </div>
            </Dialog>

            <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}}
                    header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false}
                    onHide={this.hideDeleteElementsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                    {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING"
                                                                      count={this.state.selectedElements.length}/>}
                </div>
            </Dialog>

            <Dialog id="core-image-crop" visible={this.state.elementPhotoDialog} style={{width: '550px'}}
                    header={I18n.t('PHOTO_PROCESSING')} modal
                    className="p-fluid v-genre" closable={false}
                    onHide={() => this.setState({elementPhotoDialog: false})}>

                {this.state.imageSrc && this.state.crop && this.state.zoom && this.state.aspect &&
                    <ImageCrop imageSrc={this.state.imageSrc}
                               crop={this.state.crop}
                               zoom={this.state.zoom}
                               aspect={this.state.aspect}
                               onCropComplete={this.onCropComplete}
                    />}

                <div className="p-dialog-footer">
                    <Button type="button" loading={this.state.loadingProcessingDialog}
                            label={I18n.t('CANCEL')}
                            icon="pi pi-times" className="p-button-text p-button-help"
                            onClick={this.cancelPhotoCrop}/>
                    <Button type="submit" loading={this.state.loadingProcessingDialog}
                            label={I18n.t('SAVE')}
                            icon="pi pi-check" className="p-button-text"
                            onClick={() => this.setState({elementPhotoDialog: false})}/>
                </div>
            </Dialog>
        </div>);
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(Advert);


