import React, {Component, createRef} from 'react';
import {connect} from "react-redux";
import {Button} from "primereact/components/button/Button";
import {Constant} from "./helpers/const";
import {setVolume} from "./actions/song";

class AppFooter extends Component {
    constructor(props) {
        super(props);
        this.audioRef = createRef();
        this.baseUrl = Constant.API_BASE + "/api/v1/core/files/"
        this.accessToken = JSON.parse(localStorage.getItem(Constant.ACCOUNT))?.accessToken;

        this.state = {
            url: "",
            contentType: ''
        };
        this.addVolume = this.addVolume.bind(this);
        this.removeVolume = this.removeVolume.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.audio !== this.props.audio) {
            let url = this.baseUrl + this.props.audio.identifier + "/streamAudio?token=" + encodeURIComponent(this.accessToken)
            let contentType = this.props.audio.contentType;
            this.setState({url, contentType})
            this.audioRef.current.pause();
            this.audioRef.current.load();
            this.audioRef.current.play();
            this.audioRef.current.volume = this.props.volume;
        } else if (prevProps.volume !== this.props.volume) {
            this.audioRef.current.volume = this.props.volume;
        }
    }

    addVolume() {
        if (this.audioRef.current.volume + 0.05 <= 1) {
            this.audioRef.current.volume = this.audioRef.current.volume + 0.05
            const {dispatch} = this.props;
            dispatch(setVolume(this.audioRef.current.volume))
        }
    }

    removeVolume() {
        if (this.audioRef.current.volume - 0.05 >= 0) {
            this.audioRef.current.volume = this.audioRef.current.volume - 0.05
            const {dispatch} = this.props;
            dispatch(setVolume(this.audioRef.current.volume))
        }
    }

    render() {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    className="layout-footer p-d-flex p-ai-center p-p-4 p-shadow-2">
            {this.props.audio && <>
                <Button label={this.props.audio.title} className="p-button-text p-button-rounded p-button-raised"/>
                <audio controls ref={this.audioRef}>
                    <source src={this.state.url} type={this.state.contentType}/>
                </audio>
                <Button icon="pi pi-sort-up" onClick={(e) => this.addVolume()}
                        className="p-button-rounded p-button-outlined"/>
                <Button icon="pi pi-sort-down" onClick={(e) => this.removeVolume()}
                        className="p-button-rounded p-button-help p-button-outlined"/>
            </>}
        </div>
    }
}


function mapStateToProps(state) {
    const {audio, volume} = state.song;
    return {
        audio, volume
    };
}

export default connect(mapStateToProps)(AppFooter);
