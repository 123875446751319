import React from 'react';
import {Button} from 'primereact/button'
import {BreadCrumb} from 'primereact/breadcrumb';
import {useLocation, withRouter} from 'react-router-dom';
import {I18n} from "react-redux-i18n";

const AppBreadcrumb = () => {

    const location = useLocation()
    const urls = location.pathname === '/' ? ['home', ''] : location.pathname.split('/');

    let model = []
    for (let i = 0; i < urls.length; i++) {
        let el = urls[i];
        if (el === '') {
            continue;
        }
        model.push({label: I18n.t(el)})
    }

    const home = {icon: 'pi pi-home', url: '/'}

    return (
        <div className="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center p-shadow-1">
            <BreadCrumb model={model} home={home} className="layout-breadcrumb p-pl-4 p-py-2"/>

            <div className="layout-breadcrumb-buttons p-d-flex p-ai-center p-pr-3">
                <Button type="button" icon="pi pi-cloud-upload" className="p-button p-button-rounded p-button-text p-button-plain p-mr-1"></Button>
                <Button type="button" icon="pi pi-bookmark" className="p-button p-button-rounded p-button-text p-button-plain p-mr-1"></Button>
                <Button type="button" icon="pi pi-power-off" className="p-button p-button-rounded p-button-text p-button-plain p-mr-1"></Button>
            </div>
        </div>
    );

}

export default withRouter(AppBreadcrumb);
