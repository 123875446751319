import {UAParser} from "ua-parser-js";
import * as  arch from "arch";
import {v4 as uuidv4} from 'uuid';
import React from "react";
import {I18n} from "react-redux-i18n";

export const inArray = (array, values) => {
    for (const element of values) {
        if (array.indexOf(element) !== -1) {
            return true;
        }
    }
    return false;
}

export const email = {
    value: '',
}

export const idData = {
    label: '',
    id: '',
}

export const price = {
    priceCurrencyEnum: '',
    etPrice: null,
}

export const phone = {
    dialCode: '',
    countryCode: '',
    name: '',
    formattedNumber: '',
    valid: true,
}

export const address = {
    streetNumber: '',
    streetAddress: '',
    addressLine2: '',
    city: '',
    postalOrZipCode: '',
    stateOrProvinceOrRegion: '',
    country: '',
    countryCode: '',
    label: '',
    value: {
        place_id: '',
    },
}

export const priceEnums = [
    {name: I18n.t('XAF'), code: 'XAF'},
    {name: I18n.t('XOF'), code: 'XOF'},
    {name: I18n.t('USD'), code: 'USD'},
    {name: I18n.t('EUR'), code: 'EUR'},
];

export const buildIdData = (data) => {
    if (data.hasOwnProperty('id')) {
        return {label: data.name, id: data.id}
    } else {
        return {label: data}
    }
};

export const buildIdDatas = (datas) => {
    let results = [];
    for (let index in datas) {
        let data = datas[index];
        if (data.hasOwnProperty('name')) {
            results.push({label: data.name, id: data.id});
        } else if (data.hasOwnProperty('label')) {
            results.push({label: data.label, id: data.id});
        } else {
            results.push({label: data});
        }
    }
    return results.filter((v, i, a) => a.findIndex(v2 => (v2.label === v.label)) === i)
};

export const extractCountyCodes = (countries) => {
    let results = [];
    for (let index in countries) {
        let data = countries[index];
        if (data.hasOwnProperty('code')) {
            results.push(data.code);
        } else {
            results.push(data);
        }
    }
    return results;
};

export const countryListData = [
    {"code": "COUNTRY_AF", "code3": "AFG", "name": "Afghanistan", "number": "004"},
    {"code": "COUNTRY_AL", "code3": "ALB", "name": "Albania", "number": "008"},
    {"code": "COUNTRY_DZ", "code3": "DZA", "name": "Algeria", "number": "012"},
    {"code": "COUNTRY_AS", "code3": "ASM", "name": "American Samoa", "number": "016"},
    {"code": "COUNTRY_AD", "code3": "AND", "name": "Andorra", "number": "020"},
    {"code": "COUNTRY_AO", "code3": "AGO", "name": "Angola", "number": "024"},
    {"code": "COUNTRY_AI", "code3": "AIA", "name": "Anguilla", "number": "660"},
    {"code": "COUNTRY_AQ", "code3": "ATA", "name": "Antarctica", "number": "010"},
    {"code": "COUNTRY_AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028"},
    {"code": "COUNTRY_AR", "code3": "ARG", "name": "Argentina", "number": "032"},
    {"code": "COUNTRY_AM", "code3": "ARM", "name": "Armenia", "number": "051"},
    {"code": "COUNTRY_AW", "code3": "ABW", "name": "Aruba", "number": "533"},
    {"code": "COUNTRY_AU", "code3": "AUS", "name": "Australia", "number": "036"},
    {"code": "COUNTRY_AT", "code3": "AUT", "name": "Austria", "number": "040"},
    {"code": "COUNTRY_AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031"},
    {"code": "COUNTRY_BS", "code3": "BHS", "name": "Bahamas (the)", "number": "044"},
    {"code": "COUNTRY_BH", "code3": "BHR", "name": "Bahrain", "number": "048"},
    {"code": "COUNTRY_BD", "code3": "BGD", "name": "Bangladesh", "number": "050"},
    {"code": "COUNTRY_BB", "code3": "BRB", "name": "Barbados", "number": "052"},
    {"code": "COUNTRY_BY", "code3": "BLR", "name": "Belarus", "number": "112"},
    {"code": "COUNTRY_BE", "code3": "BEL", "name": "Belgium", "number": "056"},
    {"code": "COUNTRY_BZ", "code3": "BLZ", "name": "Belize", "number": "084"},
    {"code": "COUNTRY_BJ", "code3": "BEN", "name": "Benin", "number": "204"},
    {"code": "COUNTRY_BM", "code3": "BMU", "name": "Bermuda", "number": "060"},
    {"code": "COUNTRY_BT", "code3": "BTN", "name": "Bhutan", "number": "064"},
    {"code": "COUNTRY_BO", "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068"},
    {"code": "COUNTRY_BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535"},
    {"code": "COUNTRY_BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070"},
    {"code": "COUNTRY_BW", "code3": "BWA", "name": "Botswana", "number": "072"},
    {"code": "COUNTRY_BV", "code3": "BVT", "name": "Bouvet Island", "number": "074"},
    {"code": "COUNTRY_BR", "code3": "BRA", "name": "Brazil", "number": "076"},
    {"code": "COUNTRY_IO", "code3": "IOT", "name": "British Indian Ocean Territory (the)", "number": "086"},
    {"code": "COUNTRY_BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096"},
    {"code": "COUNTRY_BG", "code3": "BGR", "name": "Bulgaria", "number": "100"},
    {"code": "COUNTRY_BF", "code3": "BFA", "name": "Burkina Faso", "number": "854"},
    {"code": "COUNTRY_BI", "code3": "BDI", "name": "Burundi", "number": "108"},
    {"code": "COUNTRY_CV", "code3": "CPV", "name": "Cabo Verde", "number": "132"},
    {"code": "COUNTRY_KH", "code3": "KHM", "name": "Cambodia", "number": "116"},
    {"code": "COUNTRY_CM", "code3": "CMR", "name": "Cameroon", "number": "120"},
    {"code": "COUNTRY_CA", "code3": "CAN", "name": "Canada", "number": "124"},
    {"code": "COUNTRY_KY", "code3": "CYM", "name": "Cayman Islands (the)", "number": "136"},
    {"code": "COUNTRY_CF", "code3": "CAF", "name": "Central African Republic (the)", "number": "140"},
    {"code": "COUNTRY_TD", "code3": "TCD", "name": "Chad", "number": "148"},
    {"code": "COUNTRY_CL", "code3": "CHL", "name": "Chile", "number": "152"},
    {"code": "COUNTRY_CN", "code3": "CHN", "name": "China", "number": "156"},
    {"code": "COUNTRY_CX", "code3": "CXR", "name": "Christmas Island", "number": "162"},
    {"code": "COUNTRY_CC", "code3": "CCK", "name": "Cocos (Keeling) Islands (the)", "number": "166"},
    {"code": "COUNTRY_CO", "code3": "COL", "name": "Colombia", "number": "170"},
    {"code": "COUNTRY_KM", "code3": "COM", "name": "Comoros (the)", "number": "174"},
    {"code": "COUNTRY_CD", "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180"},
    {"code": "COUNTRY_CG", "code3": "COG", "name": "Congo (the)", "number": "178"},
    {"code": "COUNTRY_CK", "code3": "COK", "name": "Cook Islands (the)", "number": "184"},
    {"code": "COUNTRY_CR", "code3": "CRI", "name": "Costa Rica", "number": "188"},
    {"code": "COUNTRY_HR", "code3": "HRV", "name": "Croatia", "number": "191"},
    {"code": "COUNTRY_CU", "code3": "CUB", "name": "Cuba", "number": "192"},
    {"code": "COUNTRY_CW", "code3": "CUW", "name": "Curaçao", "number": "531"},
    {"code": "COUNTRY_CY", "code3": "CYP", "name": "Cyprus", "number": "196"},
    {"code": "COUNTRY_CZ", "code3": "CZE", "name": "Czechia", "number": "203"},
    {"code": "COUNTRY_CI", "code3": "CIV", "name": "Côte d'Ivoire", "number": "384"},
    {"code": "COUNTRY_DK", "code3": "DNK", "name": "Denmark", "number": "208"},
    {"code": "COUNTRY_DJ", "code3": "DJI", "name": "Djibouti", "number": "262"},
    {"code": "COUNTRY_DM", "code3": "DMA", "name": "Dominica", "number": "212"},
    {"code": "COUNTRY_DO", "code3": "DOM", "name": "Dominican Republic (the)", "number": "214"},
    {"code": "COUNTRY_EC", "code3": "ECU", "name": "Ecuador", "number": "218"},
    {"code": "COUNTRY_EG", "code3": "EGY", "name": "Egypt", "number": "818"},
    {"code": "COUNTRY_SV", "code3": "SLV", "name": "El Salvador", "number": "222"},
    {"code": "COUNTRY_GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226"},
    {"code": "COUNTRY_ER", "code3": "ERI", "name": "Eritrea", "number": "232"},
    {"code": "COUNTRY_EE", "code3": "EST", "name": "Estonia", "number": "233"},
    {"code": "COUNTRY_SZ", "code3": "SWZ", "name": "Eswatini", "number": "748"},
    {"code": "COUNTRY_ET", "code3": "ETH", "name": "Ethiopia", "number": "231"},
    {"code": "COUNTRY_FK", "code3": "FLK", "name": "Falkland Islands (the) [Malvinas]", "number": "238"},
    {"code": "COUNTRY_FO", "code3": "FRO", "name": "Faroe Islands (the)", "number": "234"},
    {"code": "COUNTRY_FJ", "code3": "FJI", "name": "Fiji", "number": "242"},
    {"code": "COUNTRY_FI", "code3": "FIN", "name": "Finland", "number": "246"},
    {"code": "COUNTRY_FR", "code3": "FRA", "name": "France", "number": "250"},
    {"code": "COUNTRY_GF", "code3": "GUF", "name": "French Guiana", "number": "254"},
    {"code": "COUNTRY_PF", "code3": "PYF", "name": "French Polynesia", "number": "258"},
    {"code": "COUNTRY_TF", "code3": "ATF", "name": "French Southern Territories (the)", "number": "260"},
    {"code": "COUNTRY_GA", "code3": "GAB", "name": "Gabon", "number": "266"},
    {"code": "COUNTRY_GM", "code3": "GMB", "name": "Gambia (the)", "number": "270"},
    {"code": "COUNTRY_GE", "code3": "GEO", "name": "Georgia", "number": "268"},
    {"code": "COUNTRY_DE", "code3": "DEU", "name": "Germany", "number": "276"},
    {"code": "COUNTRY_GH", "code3": "GHA", "name": "Ghana", "number": "288"},
    {"code": "COUNTRY_GI", "code3": "GIB", "name": "Gibraltar", "number": "292"},
    {"code": "COUNTRY_GR", "code3": "GRC", "name": "Greece", "number": "300"},
    {"code": "COUNTRY_GL", "code3": "GRL", "name": "Greenland", "number": "304"},
    {"code": "COUNTRY_GD", "code3": "GRD", "name": "Grenada", "number": "308"},
    {"code": "COUNTRY_GP", "code3": "GLP", "name": "Guadeloupe", "number": "312"},
    {"code": "COUNTRY_GU", "code3": "GUM", "name": "Guam", "number": "316"},
    {"code": "COUNTRY_GT", "code3": "GTM", "name": "Guatemala", "number": "320"},
    {"code": "COUNTRY_GG", "code3": "GGY", "name": "Guernsey", "number": "831"},
    {"code": "COUNTRY_GN", "code3": "GIN", "name": "Guinea", "number": "324"},
    {"code": "COUNTRY_GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624"},
    {"code": "COUNTRY_GY", "code3": "GUY", "name": "Guyana", "number": "328"},
    {"code": "COUNTRY_HT", "code3": "HTI", "name": "Haiti", "number": "332"},
    {"code": "COUNTRY_HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334"},
    {"code": "COUNTRY_VA", "code3": "VAT", "name": "Holy See (the)", "number": "336"},
    {"code": "COUNTRY_HN", "code3": "HND", "name": "Honduras", "number": "340"},
    {"code": "COUNTRY_HK", "code3": "HKG", "name": "Hong Kong", "number": "344"},
    {"code": "COUNTRY_HU", "code3": "HUN", "name": "Hungary", "number": "348"},
    {"code": "COUNTRY_IS", "code3": "ISL", "name": "Iceland", "number": "352"},
    {"code": "COUNTRY_IN", "code3": "IND", "name": "India", "number": "356"},
    {"code": "COUNTRY_ID", "code3": "IDN", "name": "Indonesia", "number": "360"},
    {"code": "COUNTRY_IR", "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364"},
    {"code": "COUNTRY_IQ", "code3": "IRQ", "name": "Iraq", "number": "368"},
    {"code": "COUNTRY_IE", "code3": "IRL", "name": "Ireland", "number": "372"},
    {"code": "COUNTRY_IM", "code3": "IMN", "name": "Isle of Man", "number": "833"},
    {"code": "COUNTRY_IL", "code3": "ISR", "name": "Israel", "number": "376"},
    {"code": "COUNTRY_IT", "code3": "ITA", "name": "Italy", "number": "380"},
    {"code": "COUNTRY_JM", "code3": "JAM", "name": "Jamaica", "number": "388"},
    {"code": "COUNTRY_JP", "code3": "JPN", "name": "Japan", "number": "392"},
    {"code": "COUNTRY_JE", "code3": "JEY", "name": "Jersey", "number": "832"},
    {"code": "COUNTRY_JO", "code3": "JOR", "name": "Jordan", "number": "400"},
    {"code": "COUNTRY_KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398"},
    {"code": "COUNTRY_KE", "code3": "KEN", "name": "Kenya", "number": "404"},
    {"code": "COUNTRY_KI", "code3": "KIR", "name": "Kiribati", "number": "296"},
    {"code": "COUNTRY_KP", "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408"},
    {"code": "COUNTRY_KR", "code3": "KOR", "name": "Korea (the Republic of)", "number": "410"},
    {"code": "COUNTRY_KW", "code3": "KWT", "name": "Kuwait", "number": "414"},
    {"code": "COUNTRY_KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417"},
    {"code": "COUNTRY_LA", "code3": "LAO", "name": "Lao People's Democratic Republic (the)", "number": "418"},
    {"code": "COUNTRY_LV", "code3": "LVA", "name": "Latvia", "number": "428"},
    {"code": "COUNTRY_LB", "code3": "LBN", "name": "Lebanon", "number": "422"},
    {"code": "COUNTRY_LS", "code3": "LSO", "name": "Lesotho", "number": "426"},
    {"code": "COUNTRY_LR", "code3": "LBR", "name": "Liberia", "number": "430"},
    {"code": "COUNTRY_LY", "code3": "LBY", "name": "Libya", "number": "434"},
    {"code": "COUNTRY_LI", "code3": "LIE", "name": "Liechtenstein", "number": "438"},
    {"code": "COUNTRY_LT", "code3": "LTU", "name": "Lithuania", "number": "440"},
    {"code": "COUNTRY_LU", "code3": "LUX", "name": "Luxembourg", "number": "442"},
    {"code": "COUNTRY_MO", "code3": "MAC", "name": "Macao", "number": "446"},
    {"code": "COUNTRY_MG", "code3": "MDG", "name": "Madagascar", "number": "450"},
    {"code": "COUNTRY_MW", "code3": "MWI", "name": "Malawi", "number": "454"},
    {"code": "COUNTRY_MY", "code3": "MYS", "name": "Malaysia", "number": "458"},
    {"code": "COUNTRY_MV", "code3": "MDV", "name": "Maldives", "number": "462"},
    {"code": "COUNTRY_ML", "code3": "MLI", "name": "Mali", "number": "466"},
    {"code": "COUNTRY_MT", "code3": "MLT", "name": "Malta", "number": "470"},
    {"code": "COUNTRY_MH", "code3": "MHL", "name": "Marshall Islands (the)", "number": "584"},
    {"code": "COUNTRY_MQ", "code3": "MTQ", "name": "Martinique", "number": "474"},
    {"code": "COUNTRY_MR", "code3": "MRT", "name": "Mauritania", "number": "478"},
    {"code": "COUNTRY_MU", "code3": "MUS", "name": "Mauritius", "number": "480"},
    {"code": "COUNTRY_YT", "code3": "MYT", "name": "Mayotte", "number": "175"},
    {"code": "COUNTRY_MX", "code3": "MEX", "name": "Mexico", "number": "484"},
    {"code": "COUNTRY_FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583"},
    {"code": "COUNTRY_MD", "code3": "MDA", "name": "Moldova (the Republic of)", "number": "498"},
    {"code": "COUNTRY_MC", "code3": "MCO", "name": "Monaco", "number": "492"},
    {"code": "COUNTRY_MN", "code3": "MNG", "name": "Mongolia", "number": "496"},
    {"code": "COUNTRY_ME", "code3": "MNE", "name": "Montenegro", "number": "499"},
    {"code": "COUNTRY_MS", "code3": "MSR", "name": "Montserrat", "number": "500"},
    {"code": "COUNTRY_MA", "code3": "MAR", "name": "Morocco", "number": "504"},
    {"code": "COUNTRY_MZ", "code3": "MOZ", "name": "Mozambique", "number": "508"},
    {"code": "COUNTRY_MM", "code3": "MMR", "name": "Myanmar", "number": "104"},
    {"code": "COUNTRY_NA", "code3": "NAM", "name": "Namibia", "number": "516"},
    {"code": "COUNTRY_NR", "code3": "NRU", "name": "Nauru", "number": "520"},
    {"code": "COUNTRY_NP", "code3": "NPL", "name": "Nepal", "number": "524"},
    {"code": "COUNTRY_NL", "code3": "NLD", "name": "Netherlands (the)", "number": "528"},
    {"code": "COUNTRY_NC", "code3": "NCL", "name": "New Caledonia", "number": "540"},
    {"code": "COUNTRY_NZ", "code3": "NZL", "name": "New Zealand", "number": "554"},
    {"code": "COUNTRY_NI", "code3": "NIC", "name": "Nicaragua", "number": "558"},
    {"code": "COUNTRY_NE", "code3": "NER", "name": "Niger (the)", "number": "562"},
    {"code": "COUNTRY_NG", "code3": "NGA", "name": "Nigeria", "number": "566"},
    {"code": "COUNTRY_NU", "code3": "NIU", "name": "Niue", "number": "570"},
    {"code": "COUNTRY_NF", "code3": "NFK", "name": "Norfolk Island", "number": "574"},
    {"code": "COUNTRY_MP", "code3": "MNP", "name": "Northern Mariana Islands (the)", "number": "580"},
    {"code": "COUNTRY_NO", "code3": "NOR", "name": "Norway", "number": "578"},
    {"code": "COUNTRY_OM", "code3": "OMN", "name": "Oman", "number": "512"},
    {"code": "COUNTRY_PK", "code3": "PAK", "name": "Pakistan", "number": "586"},
    {"code": "COUNTRY_PW", "code3": "PLW", "name": "Palau", "number": "585"},
    {"code": "COUNTRY_PS", "code3": "PSE", "name": "Palestine, State of", "number": "275"},
    {"code": "COUNTRY_PA", "code3": "PAN", "name": "Panama", "number": "591"},
    {"code": "COUNTRY_PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598"},
    {"code": "COUNTRY_PY", "code3": "PRY", "name": "Paraguay", "number": "600"},
    {"code": "COUNTRY_PE", "code3": "PER", "name": "Peru", "number": "604"},
    {"code": "COUNTRY_PH", "code3": "PHL", "name": "Philippines (the)", "number": "608"},
    {"code": "COUNTRY_PN", "code3": "PCN", "name": "Pitcairn", "number": "612"},
    {"code": "COUNTRY_PL", "code3": "POL", "name": "Poland", "number": "616"},
    {"code": "COUNTRY_PT", "code3": "PRT", "name": "Portugal", "number": "620"},
    {"code": "COUNTRY_PR", "code3": "PRI", "name": "Puerto Rico", "number": "630"},
    {"code": "COUNTRY_QA", "code3": "QAT", "name": "Qatar", "number": "634"},
    {"code": "COUNTRY_MK", "code3": "MKD", "name": "Republic of North Macedonia", "number": "807"},
    {"code": "COUNTRY_RO", "code3": "ROU", "name": "Romania", "number": "642"},
    {"code": "COUNTRY_RU", "code3": "RUS", "name": "Russian Federation (the)", "number": "643"},
    {"code": "COUNTRY_RW", "code3": "RWA", "name": "Rwanda", "number": "646"},
    {"code": "COUNTRY_RE", "code3": "REU", "name": "Réunion", "number": "638"},
    {"code": "COUNTRY_BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652"},
    {"code": "COUNTRY_SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
    {"code": "COUNTRY_KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659"},
    {"code": "COUNTRY_LC", "code3": "LCA", "name": "Saint Lucia", "number": "662"},
    {"code": "COUNTRY_MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663"},
    {"code": "COUNTRY_PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666"},
    {"code": "COUNTRY_VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670"},
    {"code": "COUNTRY_WS", "code3": "WSM", "name": "Samoa", "number": "882"},
    {"code": "COUNTRY_SM", "code3": "SMR", "name": "San Marino", "number": "674"},
    {"code": "COUNTRY_ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678"},
    {"code": "COUNTRY_SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682"},
    {"code": "COUNTRY_SN", "code3": "SEN", "name": "Senegal", "number": "686"},
    {"code": "COUNTRY_RS", "code3": "SRB", "name": "Serbia", "number": "688"},
    {"code": "COUNTRY_SC", "code3": "SYC", "name": "Seychelles", "number": "690"},
    {"code": "COUNTRY_SL", "code3": "SLE", "name": "Sierra Leone", "number": "694"},
    {"code": "COUNTRY_SG", "code3": "SGP", "name": "Singapore", "number": "702"},
    {"code": "COUNTRY_SX", "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534"},
    {"code": "COUNTRY_SK", "code3": "SVK", "name": "Slovakia", "number": "703"},
    {"code": "COUNTRY_SI", "code3": "SVN", "name": "Slovenia", "number": "705"},
    {"code": "COUNTRY_SB", "code3": "SLB", "name": "Solomon Islands", "number": "090"},
    {"code": "COUNTRY_SO", "code3": "SOM", "name": "Somalia", "number": "706"},
    {"code": "COUNTRY_ZA", "code3": "ZAF", "name": "South Africa", "number": "710"},
    {"code": "COUNTRY_GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239"},
    {"code": "COUNTRY_SS", "code3": "SSD", "name": "South Sudan", "number": "728"},
    {"code": "COUNTRY_ES", "code3": "ESP", "name": "Spain", "number": "724"},
    {"code": "COUNTRY_LK", "code3": "LKA", "name": "Sri Lanka", "number": "144"},
    {"code": "COUNTRY_SD", "code3": "SDN", "name": "Sudan (the)", "number": "729"},
    {"code": "COUNTRY_SR", "code3": "SUR", "name": "Suriname", "number": "740"},
    {"code": "COUNTRY_SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744"},
    {"code": "COUNTRY_SE", "code3": "SWE", "name": "Sweden", "number": "752"},
    {"code": "COUNTRY_CH", "code3": "CHE", "name": "Switzerland", "number": "756"},
    {"code": "COUNTRY_SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760"},
    {"code": "COUNTRY_TW", "code3": "TWN", "name": "Taiwan", "number": "158"},
    {"code": "COUNTRY_TJ", "code3": "TJK", "name": "Tajikistan", "number": "762"},
    {"code": "COUNTRY_TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834"},
    {"code": "COUNTRY_TH", "code3": "THA", "name": "Thailand", "number": "764"},
    {"code": "COUNTRY_TL", "code3": "TLS", "name": "Timor-Leste", "number": "626"},
    {"code": "COUNTRY_TG", "code3": "TGO", "name": "Togo", "number": "768"},
    {"code": "COUNTRY_TK", "code3": "TKL", "name": "Tokelau", "number": "772"},
    {"code": "COUNTRY_TO", "code3": "TON", "name": "Tonga", "number": "776"},
    {"code": "COUNTRY_TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780"},
    {"code": "COUNTRY_TN", "code3": "TUN", "name": "Tunisia", "number": "788"},
    {"code": "COUNTRY_TR", "code3": "TUR", "name": "Turkey", "number": "792"},
    {"code": "COUNTRY_TM", "code3": "TKM", "name": "Turkmenistan", "number": "795"},
    {"code": "COUNTRY_TC", "code3": "TCA", "name": "Turks and Caicos Islands (the)", "number": "796"},
    {"code": "COUNTRY_TV", "code3": "TUV", "name": "Tuvalu", "number": "798"},
    {"code": "COUNTRY_UG", "code3": "UGA", "name": "Uganda", "number": "800"},
    {"code": "COUNTRY_UA", "code3": "UKR", "name": "Ukraine", "number": "804"},
    {"code": "COUNTRY_AE", "code3": "ARE", "name": "United Arab Emirates (the)", "number": "784"},
    {"code": "COUNTRY_GB", "code3": "GBR", "name": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826"},
    {"code": "COUNTRY_UM", "code3": "UMI", "name": "United States Minor Outlying Islands (the)", "number": "581"},
    {"code": "COUNTRY_US", "code3": "USA", "name": "United States of America (the)", "number": "840"},
    {"code": "COUNTRY_UY", "code3": "URY", "name": "Uruguay", "number": "858"},
    {"code": "COUNTRY_UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860"},
    {"code": "COUNTRY_VU", "code3": "VUT", "name": "Vanuatu", "number": "548"},
    {"code": "COUNTRY_VE", "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862"},
    {"code": "COUNTRY_VN", "code3": "VNM", "name": "Viet Nam", "number": "704"},
    {"code": "COUNTRY_VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092"},
    {"code": "COUNTRY_VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850"},
    {"code": "COUNTRY_WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876"},
    {"code": "COUNTRY_EH", "code3": "ESH", "name": "Western Sahara", "number": "732"},
    {"code": "COUNTRY_YE", "code3": "YEM", "name": "Yemen", "number": "887"},
    {"code": "COUNTRY_ZM", "code3": "ZMB", "name": "Zambia", "number": "894"},
    {"code": "COUNTRY_ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716"},
    {"code": "COUNTRY_AX", "code3": "ALA", "name": "Åland Islands", "number": "248"}
];

export const imageItemTemplate = (item) => {
    return <img src={item.url} alt={item.name} style={{width: '100%', display: 'block'}}/>
}

export const imageThumbnailTemplate = (item) => {
    return <img src={item.url} alt={item.name} style={{height: '50px'}}/>
}

export const getFormattedPhone = (value, country) => {
    country['formattedNumber'] = value
    return country;
}

export const getPosition = (position) => {
    return {
        latitude: position.lat(),
        longitude: position.lng()
    };
}

export const getAddressObject = (address_components) => {
    const ShouldBeComponent = {
        streetNumber: ["street_number"],
        postalOrZipCode: ["postal_code"],
        streetAddress: ["street_address", "route"],
        stateOrProvinceOrRegion: [
            "administrative_area_level_1",
            "administrative_area_level_2",
            "administrative_area_level_3",
            "administrative_area_level_4",
            "administrative_area_level_5"
        ],
        city: [
            "locality",
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4"
        ],
        country: ["country"]
    };

    const address = {
        streetNumber: "",
        postalOrZipCode: "",
        streetAddress: "",
        stateOrProvinceOrRegion: "",
        city: "",
        country: "",
        countryCode: "",
        label: "",
        value: {
            place_id: "ChIJL8_Gmv7q9EcRWDQQLqRzAwo"
        }
    };
    address_components.forEach(component => {
        for (let shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                if (shouldBe === "country") {
                    address['countryCode'] = component.short_name;
                    address['country'] = component.long_name;
                } else {
                    address[shouldBe] = component.long_name;
                }
            }
        }
    });
    return address;
}

export const localeFrFROptions = {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'jui', 'jui', 'aou', 'sep', 'oct', 'nov', 'dec'],
    today: "Aujourd'hui",
    clear: 'Supprimer'
};

export const localeEnUSOptions = {
    firstDayOfWeek: 1,
    dayNames: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
    dayNamesShort: ['sun', 'mon', 'tues', 'wed', 'thu', 'fri', 'sat'],
    dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    monthNames: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december' ],
    monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec' ],
    today: "Today",
    clear: 'Delete'
};

export const mapTranslation = (translations) => {
    const res = [];
    Object.entries(translations).map(([code, array]) => {
        const item = {code: code, translation: array[0], kind: array[1]};
        res.push(item)
        return res
    })
    return res;
}

export const mapProfileTranslation = (translations) => {
    const res = [];
    Object.entries(translations).map(([code, value]) => {
        const item = {code: code, translation: value};
        res.push(item)
        return res
    })
    return res;
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const string_to_slug = (str, separator, addUUID) => {
    str = str.trim();
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaaaeeeeiiiioooouuuunc------";

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    let result = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, "") // trim - from end of text
        .replace(/-/g, separator);

    if (addUUID) {
        result = result + '-' + uuidv4()
    }

    return result;
}

export const getBrowser = () => {
    let browser, cpu, device, engine, os, parser;
    parser = new UAParser();
    browser = parser.getBrowser();
    device = parser.getDevice();
    engine = parser.getEngine();
    os = parser.getOS();
    cpu = parser.getCPU();
    return {
        browserName: browser.name,
        browserVersion: browser.version,
        deviceModel: device.model,
        deviceType: device.type,
        deviceVendor: device.vendor,
        engineName: engine.name,
        engineVersion: engine.version,
        osName: os.name,
        osVersion: os.version,
        cpuArchitecture: !!cpu.architecture ? cpu.architecture : arch(),
        language: navigator.language,
        languages: navigator.languages,
        userAgent: navigator.userAgent,
        referrer: document.referrer || 'N/A',
        online: navigator.onLine,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screenResolution: window.screen.width + ' x ' + window.screen.height,
        cookieEnabled: navigator.cookieEnabled
    };
}

export const toBackHashMap = (array) => {
    let obj = {};
    for (let i = 0; i < array.length; i++) {
        obj[array[i].key] = array[i].value;
    }
    return obj;
}

export const toFrontHasMap = (obj) => {
    const array = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const item = {key, value: obj[key]};
            array.push(item)
        }
    }
    return array;
}

export const isFormFieldInvalid = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return !!(props.touched[name] && props.errors[name] && props.errors[name][attribute]);
    }
    return !!(props.touched[name] && props.errors[name]);
}

export const getFormErrorMessage = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return isFormFieldInvalid(props, name, attribute) &&
            <small className="p-error">{props.errors[name][attribute]}</small>;
    }
    return isFormFieldInvalid(props, name) && <small className="p-error">{props.errors[name]}</small>;
}

export const extension = (filename) => {
    const r = /.+\.(.+)$/.exec(filename);
    return r ? r[1] : "";
}

export const isFormFieldInvalid2 = (touched, errors, field) => {
    const fields = field.name.split(".");
    if (fields && errors[fields[0]] && errors[fields[0]][fields[1]] && errors[fields[0]][fields[1]][fields[2]]) {
        return errors[fields[0]][fields[1]][fields[2]];
    }
    return false
}

export const getFormErrorMessage2 = (touched, errors, field) => {
    if (isFormFieldInvalid2(touched, errors, field)) {
        const fields = field.name.split(".");
        return <small className="p-error">{errors[fields[0]][fields[1]][fields[2]]}</small>
    }
    return "";
}

export const getProfileUrl = (account) => {
    if (account == null) {
        return "/assets/images/avatar_male.png";
    }
    let accountData = account.data

    if (accountData.photo) {
        return accountData.photo.url;
    } else {
        if (accountData.genderTypeEnum === "MALE") {
            return "/assets/images/avatar_male.png";
        } else {
            return "/assets/images/avatar_female.png";
        }
    }
}

export const manageApiErrors = (errors, phoneNumberAttribute) => {
    if (!!phoneNumberAttribute) {
        Object.keys(errors).forEach(function (key) {
            if (errors[key] && errors[key][0]) {
                if (key === phoneNumberAttribute) {
                    errors[key] = {
                        "formattedNumber": errors[key][0]
                    }
                } else {
                    errors[key] = errors[key][0]
                }
            }
        });
    } else {
        Object.keys(errors).forEach(function (key) {
            if (errors[key] && errors[key][0]) {
                errors[key] = errors[key][0]
            }
        });
    }
    return errors;
}

