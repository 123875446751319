import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {PrivateRoute} from "./components/PrivateRoute";
import {Role} from "./helpers/role";
import Dashboard from "./components/Dashboard";
import DashboardAnalytics from "./components/DashboardAnalytics";
import Account from "./components/Account";
import TemplateGroup from "./components/TemplateGroup";
import Template from "./components/Template";
import Rule from "./components/Rule";
import CoreTranslation from "./components/CoreTranslation";
import ProfileTranslation from "./components/ProfileTranslation";
import CoreSettings from "./components/CoreSettings";
import ProfileSettings from "./components/ProfileSettings";
import UserSettings from "./components/UserSettings";
import Genre from "./components/Genre";
import Producer from "./components/Producer";
import Artist from "./components/Artist";
import Album from "./components/Album";
import Song from "./components/Song";
import Comment from "./components/Comment";
import PlayList from "./components/PlayList";
import Advert from "./components/Advert";
import Category from "./components/Category";
import News from "./components/News";

const AppRoute = () => {

    return (
        <div className="layout-content">
            <PrivateRoute roles={[Role.ROLE_USER]} path={["/", "/home"]} exact component={Dashboard}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/dashboard/analytics" component={DashboardAnalytics}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/accounts" component={Account}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/templates" exact component={Template}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/templates/groups" exact component={TemplateGroup}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/rules" exact component={Rule}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/translations/core" exact component={CoreTranslation}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/translations/profile" exact component={ProfileTranslation}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/core" exact component={CoreSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/profile" exact component={ProfileSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/user" exact component={UserSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/genres" component={Genre}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/producers" component={Producer}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/artists" component={Artist}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/albums" component={Album}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/songs" component={Song}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/comments" component={Comment}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/adverts" component={Advert}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/playlists" component={PlayList}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/categories" component={Category}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/news" component={News}/>
        </div>
    );

}

export default AppRoute;
