import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import {Tooltip} from 'primereact/tooltip';
import {I18n, Translate} from "react-redux-i18n";
import {getProfileUrl} from "./helpers/utils";

const AppInlineMenuDefault = (props) => {

    const isRTL = false;
    const menuKey = props.menuKey || 'inline-menu';

    const inlineMenuClassName = classNames('layout-inline-menu', {
        'layout-inline-menu-active': props.inlineMenuActive[props.menuKey]
    }, props.className);

    const isSlim = () => {
        getProfileUrl()
        return props.menuMode === 'slim';
    }

    return (
        <div className={inlineMenuClassName} style={props.style}>
            {isSlim() && <Tooltip target=".avatarTooltip" />}

            <button data-pr-tooltip="Amy Elsner" className={classNames('avatarTooltip layout-inline-menu-action p-link p-d-flex p-dir-row p-ai-center', { 'p-p-3 p-p-lg-1 p-py-3': props.horizontal, 'p-p-3': !props.horizontal })} onClick={(e) => props.onInlineMenuClick(e, menuKey)}>
                <img src={getProfileUrl(props.account)} alt="avatar" style={{ width: '32px', height: '32px' ,borderRadius: '50px'}} />
                <span className={classNames('p-d-flex p-flex-column', { 'p-ml-2': !isRTL, 'p-mr-2': isRTL })}>
                    <span className="p-text-bold">{props.displayName}</span>
                    {props.userOccupationEnums && props.userOccupationEnums.map((element, i) => {
                        return (
                            <small key={i}> {I18n.t(element)} &nbsp; </small>
                        )
                    })}
                </span>
                <i className={classNames('layout-inline-menu-icon pi pi-angle-down', { 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}></i>
            </button>

            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.inlineMenuActive[menuKey]} unmountOnExit>
                <>
                    <ul className="layout-inline-menu-action-panel">
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Settings">
                            <button className="p-d-flex p-flex-row p-ai-center p-link" >
                                <i className="pi pi-cog pi-fw"></i>
                                <Translate value='SETTINGS' />
                             </button>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Logout" >
                            <button onClick={props.logOut} className="p-d-flex p-flex-row p-ai-center p-link">
                                <i className="pi pi-power-off pi-fw"></i>
                                <Translate value='LOGOUT' />
                            </button>
                        </li>
                    </ul>
                    {isSlim() && <Tooltip target=".tooltip" />}
                </>
            </CSSTransition>

        </div>
    );
}

export default AppInlineMenuDefault;
