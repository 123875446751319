import React, {Component} from 'react'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import Card from '@material-ui/core/Card';
import {
    CardActionArea,
    CardActions,
    CardContent
} from "@material-ui/core";


class ImageCrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crop: this.props.crop,
            zoom: this.props.zoom
        };
    }

    onCropChange = (crop) => {
        this.setState({crop})
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        if (this.props.onCropComplete) {
            this.props.onCropComplete(croppedAreaPixels)
        }
    }

    onZoomChange = (zoom) => {
        this.setState({zoom})
    }

    render() {
        return (
            <Card>
                <CardActionArea>
                    <CardContent style={{height: '550px'}}>
                        <Cropper
                            image={this.props.imageSrc}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.props.aspect}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                        />
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Slider
                        value={this.state.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                    />
                </CardActions>
            </Card>
        )
    }
}

export default ImageCrop;
