import {Component} from 'react';
import {DashboardAnalyticsDefault} from "./DashboardAnalyticsDefault";

class DashboardAnalytics extends Component {

    render() {
        return (
            <DashboardAnalyticsDefault/>
        );
    }
}

export default DashboardAnalytics;

