import {SET_AUDIO_FILE, SET_AUDIO_VOLUME} from "../actions/types";

const initialState = {audio: null, volume: 0.2};

export default function song(state = initialState, action) {
    const {type, payload} = action;

    console.log("song paylod ", payload)

    switch (type) {
        case SET_AUDIO_FILE:
            return {...state, audio: payload};
        case SET_AUDIO_VOLUME:
            return {...state, volume: payload};
        default:
            return state;
    }
}
