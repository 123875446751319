import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import store from "../store";
import {inArray} from "../helpers/utils";

export const PrivateRoute = ({ component: Component, roles, permissions, ...rest }) => (
  <Route {...rest} render={props => {
    //console.log('store ', store)
    let currentAccount = store.getState().auth.account;

    let rolesAccount;
    let permissionsAccount;

    if(!!currentAccount){
      rolesAccount = currentAccount.data.roles;
      permissionsAccount = currentAccount.data.permissions;
    }

    //console.log('currentAccount ', currentAccount)

    //console.log('roles ', roles)
    //console.log('roles account ', rolesAccount)

    if (!currentAccount) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    // check if route is restricted by role an permissions
    if (!!roles && !!permissions) {
      if (!inArray(roles, rolesAccount) || !inArray(permissions, permissionsAccount)) {
        // authorised so redirect to acces denied page page
        return <Redirect to={{ pathname: '/access' }} />
      }

      //if only role is set
    } else if (!!roles) {
      if (!inArray(roles, rolesAccount)) {
        // authorised so redirect to acces denied page page
        return <Redirect to={{ pathname: '/access' }} />
      }

      //if only permission is set
    } else if (!!permissions) {
      if (!inArray(permissions, permissionsAccount)) {
        // authorised so redirect to acces denied page page
        return <Redirect to={{ pathname: '/access' }} />
      }
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)
