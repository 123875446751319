import HttpService from "./HttpService";
import {Constant} from '../helpers/const';

class AuthService {
    login(username, password, recaptcha) {
        return new HttpService().getJsonRequest()
            .post("/api/v1/gamma/auth/signin", {username, password, recaptcha});
    }

    logout() {
        localStorage.removeItem(Constant.ACCOUNT);
        localStorage.removeItem(Constant.USER_PROFILE);
    }

    register(username, email, password) {
        return new HttpService().getJsonRequest()
            .post("/api/v1/gamma/auth/signup", {
                username,
                email,
                password,
            });
    }

    setLocale(locale) {
        return new HttpService().getAuthTokenRequest()
            .post("/api/v1/gamma/auth/locale/change", {locale});
    }

    getProfile() {
        return new HttpService().getAuthTokenRequest().get("/api/v1/gamma/users/profile");
    }
}

export default new AuthService();
