import HttpService from "./HttpService";

export default class RuleService extends HttpService {
    endPoint = "/api/v1/gamma/bres"

    getFactOperators() {
        return this.getAuthTokenRequest().get(this.endPoint + "/factOperators");
    }

    getFactParameters() {
        return this.getAuthTokenRequest().get(this.endPoint + "/factParameters");
    }

    getFactTriggers() {
        return this.getAuthTokenRequest().get(this.endPoint + "/factTriggers");
    }

    getRuleActionTypes() {
        return this.getAuthTokenRequest().get(this.endPoint + "/ruleActionTypes");
    }
}

