import HttpService from "./HttpService";

export default class TemplateService extends HttpService {
    endPoint = "/api/v1/core/templates"

    test(identifier, type, contact) {
        return new HttpService().getAuthTokenRequest()
            .post(`${this.endPoint}/test`, {
                identifier,
                type,
                contact
            });
    }
}
