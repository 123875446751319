import {loadTranslations, setLocale} from "react-redux-i18n";
import {Constant} from "../helpers/const";
import AuthService from "../services/AuthService";
import {SET_MESSAGE} from "./types";
import TranslationService from "../services/TranstationService";
import store from "../store";

export const setLocaleWithFallback = (desiredLocale) => (dispatch) => {
  return AuthService.setLocale(desiredLocale).then(
    (response) => {
      const finalLocale = Object.keys(Constant.supportedLocales).includes(desiredLocale)
        ? desiredLocale
        : Constant.fallbackLocale;

      dispatch(setLocale(finalLocale));

      let account = JSON.parse(localStorage.getItem(Constant.ACCOUNT));
      if (account) {
          account.data.locale = finalLocale;
        localStorage.setItem(Constant.ACCOUNT, JSON.stringify(account))
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.code) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
}

export const getTranslations = () => (dispatch) => {
    let targetLocale = Constant.fallbackLocale;
    const account = JSON.parse(localStorage.getItem(Constant.ACCOUNT));
    if (account) {
        targetLocale = account.data.locale;
        return new TranslationService().getProfileAndDefault().then(
            async (response) => {
                await Promise.all([
                    dispatch(loadTranslations(response.data.content)),
                    store.dispatch(setLocale(targetLocale))
                ]);
                return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.code) ||
                    error.message ||
                    error.toString();

                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });

                return Promise.reject();
            }
        );
    }

    return new TranslationService().getDefault().then(
        async (response) => {
            await Promise.all([
                dispatch(loadTranslations(response.data.content)),
                store.dispatch(setLocale(targetLocale))
            ]);
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.code) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
}
