import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Link, NavLink, Redirect} from 'react-router-dom';
import {I18n, setLocale} from "react-redux-i18n";
import {Messages} from 'primereact/messages';
import {connect} from "react-redux";
import {Constant} from "../helpers/const";
import {login} from "../actions/auth";
import {getFormattedPhone, getFormErrorMessage, isFormFieldInvalid, manageApiErrors, phone} from "../helpers/utils";
import fr from 'react-phone-input-2/lang/fr.json'
import {Formik} from 'formik';
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import {Password} from "primereact/password";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from 'primereact/dropdown';
import {classNames} from "primereact/utils";
import UserService from "../services/UserService";
import {setMessage} from "../actions/message";

class Register extends Component {

    emptyElement = {
        firstName: '',
        lastName: '',
        genderTypeEnum: '',
        email: '',
        password: '',
        password2: '',
        memberTypeEnum: '',
        mobilePhone: phone,
        cgu: false
    };

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.showResponse = this.showResponse.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.elementService = new UserService();

        this.state = {
            loadingLang: false,
            username: "",
            password: "",
            loading: false,
            recaptcha: false,
            recaptchaResponse: null,
        };

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                lastName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                genderTypeEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                email: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL')),
                password: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .min(6, I18n.t('PASSWORD_SHORT')),
                password2: Yup
                    .string()
                    .required(I18n.t('FIELD_IS_REQUIRED'))
                    .oneOf([Yup.ref('password'), null], I18n.t('PASSWORD_MUST_MATCH')),
                cgu: Yup.bool().oneOf([true], 'Veuillez accepter les CGU pour continuer'),
                mobilePhone: Yup.object().shape({
                    formattedNumber: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
                }),
            })
        };
    }

    componentDidMount() {
        this.loadGenresEnums();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.loadGenresEnums();
        }
    }

    loadGenresEnums() {
        this.genresEnums = [
            {name: I18n.t('MALE'), code: 'MALE'},
            {name: I18n.t('FEMALE'), code: 'FEMALE'}
        ];
    }

    handleLanguageLinkClick = (e, code) => {
        this.setState({
            loadingLang: true
        });
        console.log("handling click")
        e.preventDefault();
        this.props.dispatch(setLocale(code))
        this.setState({
            loadingLang: false
        });
    };

    showResponse(response) {
        if (response) {
            this.setState({
                recaptchaResponse: response.response,
            });
        }
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value,
        });
    }

    handleLogin(e) {
        e.preventDefault();
        if (this.state.recaptcha === true && this.state.recaptchaResponse === null) {
            this.msgs.show([
                {severity: 'error', detail: I18n.t(Constant.RECAPTCHA_NOT_SET), sticky: false}
            ]);
            return
        }

        this.setState({
            loading: true,
        });
        const {dispatch, history} = this.props;
        dispatch(login(this.state.username, this.state.password, this.state.recaptchaResponse))
            .then((objectLogin) => {
                console.log("loginnnnnnnn ", objectLogin)
                if (objectLogin.code === "ACTIVATION_CODE_SUCCESS") {
                    history.push(`/password/${this.state.username}/${this.state.password}`)
                } else {
                    //to refresh  session data
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log("loginnnnnnnn error", error)
                this.setState({
                    loading: false,
                });

                if (this.props.message.code === "REQUIRES_CAPTCHA_ERROR") {
                    this.setState({
                        recaptcha: true,
                    });
                }

                this.msgs.show([
                    {severity: 'error', detail: I18n.t(this.props.message.code), sticky: true}
                ]);
            });
    }

    saveElement = (element, {setStatus, setErrors}) => {
        element.memberTypeEnum = "STUDENT"
        this.setState({loading: true});
        this.elementService.add(element)
            .then((response) => {
                this.setState({
                    element: this.emptyElement,
                    isRegistered: true
                });
                this.formikProps.initialValues = this.emptyElement;
                this.props.dispatch(setMessage("ACCOUNT_CREATED_SUCCESSFULLY"))
                this.props.history.push("/login");
            })
            .catch(error => {
                this.setState({
                    loading: false,
                });
                let errors = error.response.data.errors;
                setErrors(manageApiErrors(errors, "mobilePhone"));
            });
    }

    render() {
        const {isLoggedIn} = this.props;
        if (isLoggedIn) {
            return <Redirect to="/"/>;
        }

        let localeButton;
        if (this.props.locale === 'fr-FR') {
            if (!this.state.loadingLang) {
                localeButton = <button type="button" className="layout-topbar-action p-link" onClick={e => this.handleLanguageLinkClick(e, "en-US")}>
                    <img src="assets/demo/flags/flag_placeholder.png" alt='English' className="primeface-flag primeface-flag-uk" width={30} height={20}/>
                    <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text white-color">English</span>
                </button>
            } else {
                localeButton = <Button label="Loading" iconPos="right" loading/>
            }
        } else {
            if (!this.state.loadingLang) {
                localeButton = <button type="button" className="layout-topbar-action p-link" onClick={e => this.handleLanguageLinkClick(e, "fr-FR")}>
                    <img src="assets/demo/flags/flag_placeholder.png" alt='Français' className="primeface-flag primeface-flag-fr" width={30} height={20}/>
                    <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text white-color">Français</span>
                </button>
            } else {
                localeButton = <Button label="Chargement en cours" iconPos="right" loading/>
            }
        }

        return (
            <div className="pages-body login-page p-d-flex p-flex-column">
                <div className="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
                    <div className="topbar-left p-ml-3 p-d-flex">
                        <div className="logo">
                            <img src="assets/layout/images/logo2x.png" alt=""/>
                        </div>
                    </div>
                    <div className="topbar-right p-mr-3 p-d-flex">
                        {localeButton}
                    </div>
                </div>

                <div className="p-as-center p-mt-auto p-mb-auto" style={{width: '400px'}}>
                    <div className="pages-panel card p-d-flex p-flex-column">
                        <div className="pages-header p-px-3 p-py-1 p-mb-6">
                            <h2>{I18n.t('CREATE_AN_ACCOUNT')}</h2>
                        </div>

                        <div className="input-panel p-d-flex p-flex-column p-px-3">
                            <Messages ref={(el) => this.msgs = el}/>


                            <Formik
                                enableReinitialize
                                initialValues={this.formikProps.initialValues}
                                validationSchema={this.formikProps.validationSchema}
                                onSubmit={this.saveElement}
                            >
                                {props => {
                                    console.log(props)
                                    return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                                 onSubmit={props.handleSubmit} className="col-10">

                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <InputText
                                                    type="text"
                                                    name="firstName"
                                                    value={props.values.firstName}
                                                    onChange={props.handleChange}
                                                    id="firstName"/>
                                                <label htmlFor="firstName">{I18n.t('FIRST_NAME')}</label>
                                            </span>
                                        </div>

                                        <div className="p-inputgroup p-mt-3">
                                            <span className="p-float-label">
                                                <InputText
                                                    type="text"
                                                    name="lastName"
                                                    value={props.values.lastName}
                                                    onChange={props.handleChange}
                                                    id="lastName"
                                                    className={classNames({'p-invalid': isFormFieldInvalid(props, 'lastName')})}
                                                />
                                                <label htmlFor="lastName">{I18n.t('LAST_NAME')}</label>
                                            </span>
                                        </div>
                                        <div className="p-inputgroup">
                                            {getFormErrorMessage(props, 'lastName')}
                                        </div>

                                        <div className="p-inputgroup p-mt-3">
                                            <span className="p-float-label">
                                                <Dropdown
                                                    placeholder={I18n.t('CHOOSE_YOUR_SEX')}
                                                    name="genderTypeEnum"
                                                    id="genderTypeEnum"
                                                    value={props.values.genderTypeEnum}
                                                    onChange={props.handleChange}
                                                    options={this.genresEnums}
                                                    optionLabel="name"
                                                    optionValue="code"
                                                    className={classNames({'p-invalid': isFormFieldInvalid(props, 'genderTypeEnum')})}
                                                />
                                            </span>
                                        </div>
                                        <div className="p-inputgroup">
                                            {getFormErrorMessage(props, 'genderTypeEnum')}
                                        </div>

                                        <div className="p-inputgroup p-mt-3">
                                            <span className="p-float-label">
                                                <InputText
                                                    type="email"
                                                    name="email"
                                                    value={props.values.email}
                                                    onChange={props.handleChange}
                                                    id="email"
                                                    className={classNames({'p-invalid': isFormFieldInvalid(props, 'email')})}
                                                />
                                                <label htmlFor="email">{I18n.t('EMAIL')}</label>
                                            </span>
                                        </div>
                                        <div className="p-inputgroup">
                                            {getFormErrorMessage(props, 'email')}
                                        </div>

                                        <div className="p-inputgroup p-mt-3">
                                            <span className="p-float-label">
                                                <PhoneInput
                                                    placeholder={I18n.t('PHONE_NUMBER')}
                                                    isValid={props.values.mobilePhone.valid}
                                                    localization={fr}
                                                    inputStyle={{width: '100%'}}
                                                    country={'cm'}
                                                    name="mobilePhone"
                                                    value={props.values.mobilePhone.formattedNumber}
                                                    onChange={(value, country) => props.setFieldValue('mobilePhone', getFormattedPhone(value, country))}
                                                />
                                            </span>
                                        </div>
                                        <div className="p-inputgroup">
                                            {getFormErrorMessage(props, 'mobilePhone', 'formattedNumber')}
                                        </div>

                                        <div className="p-inputgroup p-mt-3">
                                            <span className="p-float-label">
                                               <Password
                                                   style={{width: '100%'}}
                                                   toggleMask
                                                   name="password"
                                                   value={props.values.password}
                                                   onChange={props.handleChange}
                                                   id="password"
                                                   feedback={false}
                                                   className={classNames({'p-invalid': isFormFieldInvalid(props, 'password')})}
                                               />
                                                <label htmlFor="password">{I18n.t('PASSWORD')}</label>
                                            </span>
                                         </div>
                                        <div className="p-inputgroup">
                                            {getFormErrorMessage(props, 'password')}
                                        </div>

                                        <div className="p-inputgroup p-mt-3">
                                            <span className="p-float-label">
                                               <Password
                                                   style={{width: '100%'}}
                                                   toggleMask
                                                   name="password2"
                                                   value={props.values.password2}
                                                   onChange={props.handleChange}
                                                   id="password2"
                                                   feedback={false}
                                                   className={classNames({'p-invalid': isFormFieldInvalid(props, 'password2')})}
                                               />
                                                 <label htmlFor="password2">{I18n.t('PASSWORD_REPEAT')}</label>
                                            </span>
                                        </div>
                                        <div className="p-inputgroup">
                                            {getFormErrorMessage(props, 'password2')}
                                        </div>

                                        <div className="p-inputgroup p-mt-3">
                                            <Checkbox
                                                name="cgu"
                                                checked={props.values.cgu}
                                                id="cgu"
                                                onChange={(event) => props.setFieldValue('cgu', event.checked)}
                                            />
                                            <label>{I18n.t('ACCEPT_TERMS_1')}&nbsp;
                                            <Link target="_blank" className="indigo-color p-text-bold" to="/register">{I18n.t('ACCEPT_TERMS_2')}&nbsp;</Link>
                                            {I18n.t('ACCEPT_TERMS_3')}&nbsp;
                                            <Link target="_blank" className="indigo-color p-text-bold" to="/register">{I18n.t('ACCEPT_TERMS_4')}&nbsp;</Link>
                                            {I18n.t('ACCEPT_TERMS_5')}
                                            </label>
                                        </div>
                                        <div className="p-inputgroup p-mb-3" >
                                            {getFormErrorMessage(props, 'cgu')}
                                        </div>
                                        <Button loading={this.state.loading} style={{width: '100%'}} className="login-button p-px-3" label={I18n.t('CREATE_AN_ACCOUNT')}></Button>

                                    </form>
                                }}
                            </Formik>
                            <div className="pages-detail p-inputgroup p-mt-2">
                                {I18n.t('ALREADY_HAVE_AN_ACCOUNT') + ' ?'} &nbsp;<NavLink className="indigo-color p-text-bold" to="/login">{I18n.t('TO_LOGIN')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const message = state.message;
    const locale = state.i18n.locale;
    return {
        isLoggedIn,
        message,
        locale
    };
}

export default connect(mapStateToProps)(Register);
