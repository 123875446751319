import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {Formik} from 'formik';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import ProducerService from "../services/ProducerService";
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import {address, countryListData, email, extractCountyCodes, getAddressObject, getFormattedPhone, getFormErrorMessage, getPosition, imageItemTemplate, imageThumbnailTemplate, isFormFieldInvalid, phone} from "../helpers/utils";
import {InputTextarea} from "primereact/inputtextarea";
import * as Yup from 'yup';
import PhoneInput from "react-phone-input-2";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import fr from 'react-phone-input-2/lang/fr.json'
import {Galleria} from "primereact/galleria";
import {AutoComplete} from "primereact/autocomplete";

class Producer extends Component {
    emptyElement = {
        name: '',
        description: '',
        email,
        phone,
        address,
        siteUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        linkedInUrl: '',
        pinterestUrl: '',
        twitterUrl: '',
        youtubeUrl: '',
        tiktokUrl: '',
        photos: [],
        countries: [],
        presentationVideo: null,
        coverPhoto: null,
        tempPhotos: [],
        tempPresentationVideo: null,
        tempCoverPhoto: null
    };


    constructor(props) {
        super(props);

        this.formikProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                name: Yup.string().required(I18n.t('NAME_IS_REQUIRED')),
                email: Yup.object().shape({
                    value: Yup.string().email(I18n.t('INVALID_EMAIL')).nullable()
                }),
                /*description: Yup.string().required(I18n.t('DESCRIPTION_IS_REQUIRED')),
                email: Yup.object().shape({
                    value: Yup.string().required(I18n.t('EMAIL_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL'))
                }),
                phone: Yup.object().shape({
                    formattedNumber: Yup.string().required(I18n.t('PHONE_NUMBER_IS_REQUIRED'))
                }),
                address: Yup.object().shape({
                    label: Yup.string().required(I18n.t('ADDRESS_IS_REQUIRED'))
                }),*/
                siteUrl: Yup.string().url(I18n.t('INVALID_URL')),
                facebookUrl: Yup.string().url(I18n.t('INVALID_URL')),
                instagramUrl: Yup.string().url(I18n.t('INVALID_URL')),
                linkedInUrl: Yup.string().url(I18n.t('INVALID_URL')),
                pinterestUrl: Yup.string().url(I18n.t('INVALID_URL')),
                twitterUrl: Yup.string().url(I18n.t('INVALID_URL')),
                youtubeUrl: Yup.string().url(I18n.t('INVALID_URL')),
                tiktokUrl: Yup.string().url(I18n.t('INVALID_URL')),
                /*photos: Yup.array()
                    .test("not-photo-required", I18n.t('IMAGE_IS_REQUIRED'), function (photos) {
                        //const {id} = this.parent;
                        return true;//return !!id && photos.length === 0;
                    }),
                presentationVideo: Yup.mixed()
                    .test("not-presentation-video-required", I18n.t('FILE_IS_REQUIRED'), function (presentationVideo) {
                        //const {id} = this.parent;
                        return true;//return !!id && !!presentationVideo;
                    }),
                coverPhoto: Yup.mixed()
                    .test("not-cover-photo-required", I18n.t('FILE_IS_REQUIRED'), function (coverPhoto) {
                        //const {id} = this.parent;
                        return true;//return !!id && !!coverPhoto;
                    })*/
            }),
            onSubmit: (element) => {
                this.saveElement(element)
            }

        };

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.elementService = new ProducerService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.handleMapAddressChange = this.handleMapAddressChange.bind(this);
        this.searchCountry = this.searchCountry.bind(this);
    }

    componentDidMount() {
        this.createOptions();
        this.setState({loading: true, countriesList: countryListData});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue).then(
            response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    searchCountry(event) {
        let filteredCountries;
        if (!event.query.trim().length) {
            filteredCountries = [...this.state.countriesList];
        } else {
            filteredCountries = this.state.countriesList.filter((country) => {
                return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        this.setState({filteredCountries});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('EDIT_DATA'), icon: 'pi pi-fw pi-pencil', command: () => this.editElement(this.state.selectedElement)},
            {label: I18n.t('DELETE_DATA'), icon: 'pi pi-fw pi-trash', command: () => this.confirmDeleteElement(this.state.selectedElement)}
        ];

        const sortFilterValues = [
            {name: I18n.t('NAME') + ' ' + I18n.t('ASCENDING'), field: 'name', order: Order.ASC},
            {name: I18n.t('NAME') + ' ' + I18n.t('DESCENDING'), field: 'name', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        this.elementService.getPage(page, rows, this.state.sortFilterValue, this.state.searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            elementDialog: true
        });
    }

    hideDialog() {
        this.formikProps.initialValues = this.emptyElement;
        this.setState({
            elementDialog: false,
            element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    handleMapAddressChange = (props, initialAddress) => {
        if (initialAddress) {
            let placeId = initialAddress["value"]["place_id"];
            geocodeByPlaceId(placeId)
                .then(results => {
                    if (results[0]) {
                        let address = getAddressObject(results[0].address_components);
                        address.label = initialAddress.label
                        address.value.place_id = initialAddress.value.place_id
                        let position = getPosition(results[0].geometry.location);
                        props.setFieldValue('address', address)
                        props.setFieldValue('position', position)
                    }
                })
                .catch(error => console.error("erreur found " + error));
        }
    }

    saveElement(element) {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        const data = new FormData();
        let files = element.photos;
        for (let i = 0; i < files.length; i++) {
            data.append("photos", files[i], files[i].name);
        }
        if (element.presentationVideo) {
            data.append("presentationVideo", element.presentationVideo, element.presentationVideo.name);
        }
        if (element.coverPhoto) {
            data.append("coverPhoto", element.coverPhoto, element.coverPhoto.name);
        }
        data.append("data", JSON.stringify(element));
        if (element.id) { //may be update
            this.elementService.edit(element.id, data)
                .then((response) => {
                    const index = this.findIndexById(element.id);
                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        } else { //may be creation
            this.elementService.add(data)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.formikProps.initialValues = this.emptyElement;
                    this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
                });
        }
    }

    editElement(element) {
        element.tempPhotos = element.photos
        element.tempPresentationVideo = element.presentationVideo
        element.tempCoverPhoto = element.coverPhoto
        element.photos = []
        element.coverPhoto = null
        element.presentationVideo = null
        element.address.value = {place_id: '',}
        this.formikProps.initialValues = element;
        this.setState({
            elementDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue, searchFilterValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000});
            });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
                <Button label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label={I18n.t('IMPORT_DATA')} chooseLabel="Import" className="p-mr-2 p-d-inline-block"/>
                <Button label={I18n.t('EXPORT_DATA')} icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV}/>
            </React.Fragment>
        )
    }

    addPhoto = (photos, file) => {
        if (!photos.some(photo => photo.name === file.name)) {
            photos.push(file)
        }
        return photos;
    };

    removePhoto = (photos, file) => {
        return photos.filter(photo => photo.name !== file.name)
    };

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('PRODUCERS')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue} onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue} onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
            </div>
        );
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );
        const deleteElementsDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedElements}/>
            </React.Fragment>
        );

        const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editElement(rowData)}/>
                </React.Fragment>
            );
        }

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el} onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}/>
                    <DataTable ref={(el) => this.dt = el} value={this.state.elements} selection={this.state.selectedElements} onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement} onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>
                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
                        <Column field="name" header={I18n.t('NAME')}/>
                        <Column field="siteUrl" header={I18n.t('SITE_URL')}/>
                        <Column field="email.value" header={I18n.t('EMAIL')}/>
                        <Column field="phone.formattedNumber" header={I18n.t('PHONE_NUMBER')}/>
                        <Column headerStyle={{width: '3rem'}} body={actionBodyTemplate}/>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '550px'}} header={I18n.t('DETAILS')} modal className="p-fluid" closable={false} onHide={this.hideDialog}>
                    <Formik {...this.formikProps} >
                        {props => {
                            console.log(props.values)
                            return <form onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()} onSubmit={props.handleSubmit} className="p-fluid">
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        autoFocus
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        id="name"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'name')})}
                                    />
                                    {getFormErrorMessage(props, 'name')}
                                    <label htmlFor="name" className={classNames({'p-error': isFormFieldInvalid(props, 'name')})}>{I18n.t('NAME')}*</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        rows={10}
                                        name="description"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'description')})}
                                    />
                                    {getFormErrorMessage(props, 'description')}
                                    <label htmlFor="description" className={classNames({'p-error': isFormFieldInvalid(props, 'description')})}>{I18n.t('DESCRIPTION')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <AutoComplete
                                        id="countries"
                                        name="countries"
                                        value={props.values.countries}
                                        suggestions={this.state.filteredCountries}
                                        completeMethod={this.searchCountry}
                                        field="name"
                                        multiple
                                        onChange={(event) => props.setFieldValue('countries', extractCountyCodes(event.value))}
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'countries')})}
                                    />
                                    {getFormErrorMessage(props, 'countries')}
                                    <label htmlFor="countries" className={classNames({'p-error': isFormFieldInvalid(props, 'countries')})}>{I18n.t('COUNTRIES_NAMES')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="email"
                                        value={props.values.email.value}
                                        onChange={(event) => props.setFieldValue('email.value', event.target.value)}
                                        id="email"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'email', 'value')})}
                                    />
                                    {getFormErrorMessage(props, 'email', 'value')}
                                    <label htmlFor="email" className={classNames({'p-error': isFormFieldInvalid(props, 'email', 'value')})}>{I18n.t('EMAIL')}</label>
                                </div>

                                <div className="p-field" style={{marginTop: '10px'}}>
                                    <label htmlFor="phone" className={"p-d-block " + classNames({'p-error': isFormFieldInvalid(props, 'phone', 'formattedNumber')})}>{I18n.t('PHONE_NUMBER')}</label>
                                    <PhoneInput
                                        isValid={props.values.phone.valid}
                                        localization={fr}
                                        inputStyle={{width: '100%'}}
                                        country={'cm'}
                                        name="phone"
                                        value={props.values.phone.formattedNumber}
                                        onChange={(value, country) => props.setFieldValue('phone', getFormattedPhone(value, country))}
                                    />
                                    {getFormErrorMessage(props, 'phone', 'formattedNumber')}
                                </div>

                                <div className="p-field" style={{marginTop: '10px'}}>
                                    <label htmlFor="address" className={"p-d-block " + classNames({'p-error': isFormFieldInvalid(props, 'address', 'label')})}>{I18n.t('ADDRESS')}</label>
                                    <GooglePlacesAutocomplete
                                        name="address"
                                        apiKey={this.props.settings.GMAPS_KEY}
                                        apiOptions={{language: 'fr', region: 'fr'}}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ['cm', 'fr', 'us'],
                                            }
                                        }}

                                        selectProps={{
                                            onChange: (data) => {
                                                this.handleMapAddressChange(props, data)
                                            },
                                            value: props.values.address,
                                            placeholder: I18n.t('ENTER_CITY_OR_NEIGHBORHOOD'),
                                            styles: {
                                                input: (provided) => ({
                                                    ...provided,
                                                    color: "#222222",
                                                }),
                                                option: (provided) => ({
                                                    ...provided,
                                                    color: "#222222",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#222222",
                                                }),
                                            },
                                        }}
                                    />
                                    {getFormErrorMessage(props, 'address', 'label')}
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="addressLine2"
                                        value={props.values.address.addressLine2}
                                        onChange={(event) => props.setFieldValue('address.addressLine2', event.target.value)}
                                        id="addressLine2"
                                    />
                                    <label htmlFor="addressLine2">{I18n.t('ADDITIONAL_ADDRESS')}</label>
                                    <small id="addressLine2" className="p-d-block">{I18n.t('ADDITIONAL_ADDRESS_PLACEHOLDER')}</small>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="siteUrl"
                                        value={props.values.siteUrl}
                                        onChange={props.handleChange}
                                        id="siteUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'siteUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'siteUrl')}
                                    <label htmlFor="siteUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'siteUrl')})}>{I18n.t('SITE_URL')}</label>
                                    <small id="siteUrl" className="p-d-block">{I18n.t('SITE_SAMPLE_PLACEHOLDER')}</small>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="facebookUrl"
                                        value={props.values.facebookUrl}
                                        onChange={props.handleChange}
                                        id="facebookUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'facebookUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'facebookUrl')}
                                    <label htmlFor="facebookUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'facebookUrl')})}>{I18n.t('FACEBOOK_URL')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="instagramUrl"
                                        value={props.values.instagramUrl}
                                        onChange={props.handleChange}
                                        id="instagramUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'instagramUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'instagramUrl')}
                                    <label htmlFor="instagramUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'instagramUrl')})}>{I18n.t('INSTAGRAM_URL')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="linkedInUrl"
                                        value={props.values.linkedInUrl}
                                        onChange={props.handleChange}
                                        id="linkedInUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'linkedInUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'linkedInUrl')}
                                    <label htmlFor="linkedInUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'linkedInUrl')})}>{I18n.t('LINKEDIN_URL')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="pinterestUrl"
                                        value={props.values.pinterestUrl}
                                        onChange={props.handleChange}
                                        id="pinterestUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'pinterestUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'pinterestUrl')}
                                    <label htmlFor="pinterestUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'pinterestUrl')})}>{I18n.t('PINTEREST_URL')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="twitterUrl"
                                        value={props.values.twitterUrl}
                                        onChange={props.handleChange}
                                        id="twitterUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'twitterUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'twitterUrl')}
                                    <label htmlFor="twitterUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'twitterUrl')})}>{I18n.t('TWITTER_URL')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="youtubeUrl"
                                        value={props.values.youtubeUrl}
                                        onChange={props.handleChange}
                                        id="youtubeUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'youtubeUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'youtubeUrl')}
                                    <label htmlFor="youtubeUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'youtubeUrl')})}>{I18n.t('YOUTUBE_URL')}</label>
                                </div>

                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputText
                                        name="tiktokUrl"
                                        value={props.values.tiktokUrl}
                                        onChange={props.handleChange}
                                        id="tiktokUrl"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, 'tiktokUrl')})}
                                    />
                                    {getFormErrorMessage(props, 'tiktokUrl')}
                                    <label htmlFor="tiktokUrl" className={classNames({'p-error': isFormFieldInvalid(props, 'tiktokUrl')})}>{I18n.t('TIKTOK_URL')}</label>
                                </div>

                                {props.values.tempPhotos != null && props.values.tempPhotos.length > 0 && <div className="card">
                                    <Galleria value={props.values.tempPhotos} style={{maxWidth: '640px'}}
                                              item={imageItemTemplate} thumbnail={imageThumbnailTemplate}/>
                                </div>}
                                <div className="p-field">
                                    <FileUpload chooseLabel={props.values.id ? I18n.t('CHANGE_PHOTOS') : I18n.t('CHOOSE_PHOTOS')} uploadLabel={I18n.t('UPLOAD')} cancelLabel={I18n.t('CANCEL')} name="files[]"
                                                customUpload
                                                onSelect={(event) => props.setFieldValue('photos', this.addPhoto(props.values.photos, event.files[0]))}
                                                onClear={() => props.setFieldValue('photos', [])}
                                                onRemove={(event) => props.setFieldValue('photos', this.removePhoto(props.values.photos, event.file))}
                                                accept="image/*" maxFileSize={100000000}
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, 'photos')})}
                                    />
                                    {getFormErrorMessage(props, 'photos')}
                                </div>

                                {!!props.values.tempPresentationVideo &&
                                <video width="100%" controls>
                                    <source src={props.values.tempPresentationVideo.url} type={props.values.tempPresentationVideo.contentType}/>
                                    Your browser does not support the video tag.
                                </video>}
                                <div className="p-field">
                                    <FileUpload chooseLabel={props.values.id ? I18n.t('CHANGE_PRESENTATION_VIDEO') : I18n.t('CHOOSE_PRESENTATION_VIDEO')} uploadLabel={I18n.t('UPLOAD')} cancelLabel={I18n.t('CANCEL')} name="presentationVideo"
                                                customUpload
                                                disabled={!!props.values.presentationVideo}
                                                onSelect={(event) => props.setFieldValue('presentationVideo', event.files[0])}
                                                onClear={() => props.setFieldValue('presentationVideo', null)}
                                                onRemove={(event) => props.setFieldValue('presentationVideo', null)}
                                                accept="video/mp4,video/x-m4v,video/*" maxFileSize={100000000}
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, 'presentationVideo')})}
                                    />
                                    {getFormErrorMessage(props, 'presentationVideo')}
                                </div>

                                {!!props.values.tempCoverPhoto &&
                                <img src={props.values.tempCoverPhoto.url} alt={props.values.tempCoverPhoto.name} style={{width: '100%', display: 'block'}}/>}
                                <div className="p-field">
                                    <FileUpload chooseLabel={props.values.id ? I18n.t('CHANGE_COVER_PHOTO') : I18n.t('CHOOSE_COVER_PHOTO')} uploadLabel={I18n.t('UPLOAD')} cancelLabel={I18n.t('CANCEL')} name="coverPhoto"
                                                customUpload
                                                disabled={!!props.values.coverPhoto}
                                                onSelect={(event) => props.setFieldValue('coverPhoto', event.files[0])}
                                                onClear={() => props.setFieldValue('coverPhoto', null)}
                                                onRemove={(event) => props.setFieldValue('coverPhoto', null)}
                                                accept="image/*" maxFileSize={100000000}
                                                className={classNames({'p-invalid': isFormFieldInvalid(props, 'coverPhoto')})}
                                    />
                                    {getFormErrorMessage(props, 'coverPhoto')}
                                </div>

                                <div className="p-dialog-footer">
                                    <Button type="button" loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text p-button-help" onClick={this.hideDialog}/>
                                    <Button type="submit" loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text"/>
                                </div>
                            </form>
                        }
                        }
                    </Formik>

                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.name}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false} onHide={this.hideDeleteElementsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING" count={this.state.selectedElements.length}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    const settings = state.settings;
    return {
        locale,
        settings
    };
}

export default connect(mapStateToProps)(Producer);


