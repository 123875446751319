import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {NavLink, Redirect} from 'react-router-dom';
import {I18n, setLocale} from "react-redux-i18n";
import {Messages} from 'primereact/messages';
import {connect} from "react-redux";
import {Constant} from "../helpers/const";
import {login} from "../actions/auth";
import {clearMessage} from "../actions/message";
import {Message} from "primereact/message";

class Login extends Component {

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.showResponse = this.showResponse.bind(this);

        this.state = {
            loadingLang: false,
            username: "",
            password: "",
            loading: false,
            recaptcha: false,
            recaptchaResponse: null,
        };
    }

    handleLanguageLinkClick = (e, code) => {
        this.setState({
            loadingLang: true
        });
        console.log("handling click")
        e.preventDefault();
        this.props.dispatch(setLocale(code))
        this.setState({
            loadingLang: false
        });
    };

    showResponse(response) {
        if (response) {
            this.setState({
                recaptchaResponse: response.response,
            });
        }
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value,
        });
    }

    handleLogin(e) {
        e.preventDefault();
        if (this.state.recaptcha === true && this.state.recaptchaResponse === null) {
            this.msgs.show([
                {severity: 'error', detail: I18n.t(Constant.RECAPTCHA_NOT_SET), sticky: false}
            ]);
            return
        }

        this.setState({
            loading: true,
        });
        const {dispatch, history} = this.props;
        dispatch(login(this.state.username, this.state.password, this.state.recaptchaResponse))
            .then((objectLogin) => {
                console.log("loginnnnnnnn ", objectLogin)
                if (objectLogin.code === "ACTIVATION_CODE_SUCCESS") {
                    history.push(`/password/${this.state.username}/${this.state.password}`)
                } else {
                    //to refresh  session data
                    this.props.dispatch(clearMessage())
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log("loginnnnnnnn error", error)
                this.setState({
                    loading: false,
                });

                if (this.props.message.code === "REQUIRES_CAPTCHA_ERROR") {
                    this.setState({
                        recaptcha: true,
                    });
                }

                this.msgs.show([
                    {severity: 'error', detail: I18n.t(this.props.message.code), sticky: true}
                ]);
            });
    }

    render() {
        const {isLoggedIn} = this.props;
        if (isLoggedIn) {
            return <Redirect to="/"/>;
        }

        let localeButton;
        if (this.props.locale === 'fr-FR') {
            if (!this.state.loadingLang) {
                localeButton = <button type="button" className="layout-topbar-action p-link" onClick={e => this.handleLanguageLinkClick(e, "en-US")}>
                    <img src="assets/demo/flags/flag_placeholder.png" alt='English' className="primeface-flag primeface-flag-uk" width={30} height={20}/>
                    <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text white-color">English</span>
                </button>
            } else {
                localeButton = <Button label="Loading" iconPos="right" loading/>
            }
        } else {
            if (!this.state.loadingLang) {
                localeButton = <button type="button" className="layout-topbar-action p-link" onClick={e => this.handleLanguageLinkClick(e, "fr-FR")}>
                    <img src="assets/demo/flags/flag_placeholder.png" alt='Français' className="primeface-flag primeface-flag-fr" width={30} height={20}/>
                    <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text white-color">Français</span>
                </button>
            } else {
                localeButton = <Button label="Chargement en cours" iconPos="right" loading/>
            }
        }

        let messageCode = this.props.message.code
        if (messageCode && messageCode !== "OPERATION_SUCCESSFUL") {
            this.message = <Message className="p-mb-2" severity="success" text={I18n.t(messageCode)}/>
        }

        return (
            <div className="pages-body login-page p-d-flex p-flex-column">
                <div className="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
                    <div className="topbar-left p-ml-3 p-d-flex">
                        <div className="logo">
                            <img src="assets/layout/images/logo2x.png" alt=""/>
                        </div>
                    </div>
                    <div className="topbar-right p-mr-3 p-d-flex">
                        {localeButton}
                    </div>
                </div>

                <div className="p-as-center p-mt-auto p-mb-auto" style={{width: '400px'}}>
                    <div className="pages-panel card p-d-flex p-flex-column">
                        <div className="pages-header p-px-3 p-py-1">
                            <h2>{I18n.t('LOGIN')}</h2>
                        </div>

                        <h4>{I18n.t('WELCOME')}</h4>

                        <div className="pages-detail p-mb-6 p-px-6">{I18n.t('SIGN_IN_MESSAGE')}</div>

                        <div className="input-panel p-d-flex p-flex-column p-px-3">
                            {this.message}
                            <Messages ref={(el) => this.msgs = el}/>
                            <form style={{width: '100%'}}
                                  onSubmit={this.handleLogin}
                                  ref={(c) => {
                                      this.form = c;
                                  }}>
                                <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                    <span className="p-float-label">
                                    <InputText
                                        required
                                        type="email"
                                        name="username"
                                        value={this.state.username}
                                        onChange={this.onChangeUsername}
                                        id="inputgroup1"/>
                                    <label htmlFor="inputgroup1">{I18n.t('EMAIL')}</label>
							    </span>
                                </div>

                                <div className="p-inputgroup p-mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText
                                            required
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                            id="inputgroup2"/>
                                        <label htmlFor="inputgroup2">{I18n.t('PASSWORD')}</label>
							        </span>
                                </div>
                                <div className="p-inputgroup p-mb-4">
                                    <Button label={I18n.t('FORGOT_PASSWORD') + ' ?'} className="p-button-link"/>
                                </div>
                                <Button loading={this.state.loading} style={{width: '100%'}} className="login-button p-px-3" label={I18n.t('LOGIN')}></Button>
                            </form>
                            <div className="pages-detail p-inputgroup p-mt-2">
                                {I18n.t('YOU_ARE_NEW') + ' ?'} &nbsp;<NavLink className="indigo-color p-text-bold" to="/register">{I18n.t('CREATE_AN_ACCOUNT')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const message = state.message;
    const locale = state.i18n.locale;
    return {
        isLoggedIn,
        message,
        locale
    };
}

export default connect(mapStateToProps)(Login);
