import HttpService from "./HttpService";

export default class SettingService extends HttpService {
    endPoint = "/api/v1/core/settings"

    edit(data) {
        return this.getAuthTokenRequest()
            .patch(this.endPoint, data);
    }

    getDefault() {
        return this.getJsonRequest().get(this.endPoint + "/default");
    }

    getProfile() {
        return this.getJsonRequest().get(this.endPoint + "/profile");
    }

    getAccount() {
        return this.getJsonRequest().get(this.endPoint + "/account");
    }

    getRawDefault() {
        return this.getAuthTokenRequest().get(this.endPoint + "/rawDefault");
    }

    getRawProfile() {
        return this.getAuthTokenRequest().get(this.endPoint + "/rawProfile");
    }

    getRawAccount() {
        return this.getAuthTokenRequest().get(this.endPoint + "/rawAccount");
    }

    getProfileAndDefault() {
        return this.getAuthTokenRequest().get(this.endPoint + "/profileAndDefault");
    }

    getAccountAndDefault() {
        return this.getAuthTokenRequest().get(this.endPoint + "/accountAndDefault");
    }
}
