import React, {Component} from 'react';
import {I18n} from "react-redux-i18n";
import {connect} from "react-redux";
import {Constant} from "../helpers/const";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {Message} from "primereact/message";
import {ProgressSpinner} from "primereact/progressspinner";


class DocViewer2 extends Component {

    render() {
        const loadingRenderer = ({document, fileName}) => {
            return <div className="p-grid p-justify-center">
                <ProgressSpinner/>
            </div>
        };

        const noRenderer = ({document, fileName}) => {
            return <div className="p-col-12 p-md-3">
                <Message severity="error" text={I18n.t('LOADING_FILE_ERROR')}/>
            </div>
        };

        const headers = {
            "Authorization": `Bearer ${this.props.accessToken}`
        };

        return (
            <DocViewer
                documents={[
                    {
                        uri: Constant.API_BASE + `/api/v1/core/files/${this.props.file.identifier}/downloadDocument`,
                        fileName: this.props.file.name
                    }]} pluginRenderers={DocViewerRenderers}
                config={{
                    loadingRenderer: {
                        overrideComponent: loadingRenderer,
                        showLoadingTimeout: false
                    },
                    noRenderer: {
                        overrideComponent: noRenderer,
                    }
                }}
                language={this.props.locale}
                requestHeaders={headers}
            />
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    const accessToken = state.auth.account.accessToken;
    return {
        locale,
        accessToken
    };
}

export default connect(mapStateToProps)(DocViewer2);


