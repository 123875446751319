export const Constant = {
    WS_CHAT_PREFIX: 'WS_CHAT',
    WS_PREFIX: 'WS',
    WS_NOTIFICATION_PREFIX: 'WS_NOTIFICATION',
    ACCOUNT: '_voicendO0ZAAAAAl_',
    LOCALE: '_voicendO0ZAAAAAlORDDLFK_',
    USER_PROFILE: '_voicendO0ZAAAAAlell_',
    API_BASE: getApiBaseUrl(),
    WS_API_BASE: "ws://localhost:9000",
    RECAPTCHA_SITE_KEY: "6LdPiO0ZAAAAAIrvraJPYSeKYbxL50jjCq_w6p2b",
    RECAPTCHA_NOT_SET: "RECAPTCHA_NOT_SET",
    fallbackLocale: "fr-FR",
    supportedLocales: {
        'en-US': "English",
        'fr-FR': "Français",
    }
}

function getApiBaseUrl() {
    if (process.env.NODE_ENV === 'development') {
        return "http://localhost:9000";
    }
    return 'API_BASE_URL';
}
